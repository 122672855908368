import {
  faCaretDown,
  faCaretUp,
  faCircleExclamation,
  faPenToSquare,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormLabel,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Loader from '../../../components/atoms/Loader'
import CustomButton from '../../../components/Button/CustomButton'
import DatePickerComponent from '../../../components/Input/DatePicker'
import SelectInput from '../../../components/Input/SelectInput'
import TextField from '../../../components/Input/TextInput'
import ModalAddBatch from '../../../components/modal/batch/ModalAddBatch'
import ModalAddCategoryItem from '../../../components/modal/items/ModalAddCategoryItem'
import ModalUpdateItemCoaPrDraft from '../../../components/modal/pr/ModalUpdateItemCoaPrDraft'
import ModalUpdateVehiclePrDraft from '../../../components/modal/pr/ModalUpdateVehiclePrDraft'
import api from '../../../config/AxiosInstance'
import {
  capitalizeWords,
  convertDateWithoutTime,
  transformKey
} from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import Select from 'react-select'
import {
  PurchaseReceiptCreateImportSchema,
  PurchaseReceiptCreateLocalSchema
} from '../../../utils/validationSchema'
import styles from './pr.module.css'

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}
const FILE_SIZE = 5000 * 1024 // 5MB in bytes
const PurchaseReceiptCreate = () => {
  const location = useLocation()
  const [poData, setPoData] = useState({})
  const uploadFileAttachment = useRef(null)
  const navigate = useNavigate()
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [open, setOpen] = useState(true)
  const [icon, setIcon] = useState(faCaretUp)
  const [areaOptions, setAreaOptions] = useState([])
  const [openVehicle, setOpenVehicle] = useState(true)
  const [iconVehicle, setIconVehicle] = useState(faCaretUp)
  const [openItemCoa, setOpenItemCoa] = useState(true)
  const [iconItemCoa, setIconItemCoa] = useState(faCaretUp)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [modalAddBatch, setModalAddBatch] = useState(false)
  const [modalAddCategory, setModalAddCategory] = useState(false)
  const [modalUpdateItemShow, setModalUpdateItemShow] = useState(false)
  const [attachments, setAttachments] = useState([])
  const pr_type = location.state.type
  const method = location.state.methodValue
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(0)
  const [selectedWarehouse, setSelectedWarehouse] = useState('')
  const refSrcWarehouses = useRef()
  const [itemCoas, setItemCoas] = useState([])
  const [selectedItemCoa, setSelectedItemCoa] = useState(0)
  const [items, setItems] = useState([])
  const [inputBatch, setInputBatch] = useState('')
  const [batchId, setBatchId] = useState(0)
  const [itemId, setItemId] = useState(0)
  const [options, setOptions] = useState([])
  const [newOption, setNewOption] = useState(null)
  const [selectItem, setSelectItem] = useState({})
  const [dataItemSelect, setDataItemSelect] = useState()
  const [warehouses, setWarehouses] = useState([])
  const [dataWarehouses, setDataWarehouses] = useState([])
  const [typeWarehouseExternal, setTypeWarehouseExternal] = useState(null)
  const [itemCoaIdValue, setItemCoaIdValue] = useState(null)
  const [totalPack, setTotalPack] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [series, setSeries] = useState([])
  const [listItemsId, setListItemsId] = useState({})
  const [simDocFile, setSimDocFile] = useState(false)
  const [stnkDocFile, setStnkDocFile] = useState(false)
  const [coaDocFile, setCoaDocFile] = useState(false)
  const [loadingArea, setLoadingArea] = useState(false)
  const { code } = useParams()

  const loadData = async () => {
    try {
      setIsLoadingPage(true)
      const res = await api.get(`inbound/purchase-order/show?po=${code}`)
      setPoData(res.data.data)
      getWarehouses(res.data.data.branch)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoadingPage(false)
    }
  }

  const loadItems = async () => {
    try {
      const res = await api.get(`/inbound/pr-draft/show-items?po=${code}`)
      const { data } = res.data
      setItems(data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = (values) => {
    const title =
      method === 'cross_dock' || typeWarehouseExternal ? '' : 'Are you sure?'
    let text = 'This will also create new inbound document. Proceed ?'
    let confirmButtonText = 'Yes, add it!'
    if (method === 'cross_dock') {
      text = 'Create Cross Doc PR Draft?'
      confirmButtonText = 'Create'
    }
    if (method !== 'cross_dock' && typeWarehouseExternal) {
      text = 'Create PR Draft?'
      confirmButtonText = 'Create'
    }
    Swal.fire({
      title,
      text,
      icon: 'warning',
      iconColor: '#828282',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText,
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-cancel-pr'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          if (pr_type === 'import') {
            values.pib_date = moment(values.pib_date).format('YYYY-MM-DD')
          }
          let data = null
          if (!typeWarehouseExternal) {
            if (method !== 'cross_dock') {
              data = {
                ...values,
                vehicles: vehicles,
                item_coas: itemCoas,
                po_num: poData.po_name
              }
            } else {
              data = {
                ...values,
                item_coas: itemCoas,
                po_num: poData.po_name
              }
            }
          } else {
            data = {
              ...values,
              vehicles: vehicles,
              item_coas: itemCoas?.reduce((accCoa, cur) => {
                accCoa.push({
                  ...cur,
                  area_id: cur.area_id.value
                })
                return accCoa
              }, []),
              po_num: poData.po_name,
              attachment: attachments.reduce((acc, cur) => {
                acc.push(cur)
                return acc
              }, [])
            }
          }
          data.accepted_warehouse = selectedWarehouse
          data.pr_method = method
          const res = await api.postForm(`/inbound/pr-draft`, data)
          if (res.data.success) {
            navigate('/inbound/purchase-receipt')
            Common.showPopUpSuccessMessage(res.data.message)
            setIsLoading(false)
          }
        } catch (error) {
          console.log(error)
          const errMsg =
            error?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
          setIsLoading(false)
        }
      }
    })
  }

  const handleChangeUploadAttachment = (e) => {
    const file = e.target.files[0]
    if (!file) {
      const errMsg = 'File attachment is required'
      Common.showPopUpErrorMessage(errMsg)
      return
    }

    if (file.type !== 'application/pdf') {
      const errMsg = 'File attachment must be file PDF'
      Common.showPopUpErrorMessage(errMsg)
      return
    }

    if (file.size > FILE_SIZE) {
      const errMsg = 'File attachment must be less than 5MB'
      Common.showPopUpErrorMessage(errMsg)
      return
    }
    setAttachments((prevAttachments) => [...prevAttachments, file])
  }

  const handleRemoveUploadAttachment = (index) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    )
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (!file) {
      const errMsg = 'File attachment is required'
      Common.showPopUpErrorMessage(errMsg)
      setIsDragging(false)
      return
    }
    if (file.type !== 'application/pdf') {
      const errMsg = 'File attachment must be file PDF'
      Common.showPopUpErrorMessage(errMsg)
      setIsDragging(false)
      return
    }
    if (file.size > FILE_SIZE) {
      const errMsg = 'File attachment must be less than 5MB'
      Common.showPopUpErrorMessage(errMsg)
      return
    }
    setAttachments((prevAttachments) => [...prevAttachments, file])
    event.dataTransfer.clearData()
  }

  const addVehicle = (values) => {
    const isPlateNoExists = vehicles.some(
      (vehicle) => vehicle.plate_no === values
    )

    if (isPlateNoExists) {
      Common.showPopUpErrorMessage('Plate No already in list')
      return
    }

    const newVehicle = {
      id: vehicles.length + 1,
      plate_no: values,
      sim: '',
      sim_validity: null,
      sim_notes: '',
      stnk: '',
      stnk_validity: null,
      stnk_notes: '',
      sim_doc: '',
      stnk_doc: ''
    }

    setVehicles([...vehicles, newVehicle])
  }

  useEffect(() => {
    if (vehicles.length > 0) {
      const simDocs = vehicles.map((vehicle) => vehicle.sim_doc)
      const stnkDocs = vehicles.map((vehicle) => vehicle.stnk_doc)
      const hasNullSimDoc = simDocs.some((simDoc) => simDoc === '')
      const hasNullStnkDoc = stnkDocs.some((stnkDoc) => stnkDoc === '')
      setSimDocFile(!hasNullSimDoc)
      setStnkDocFile(!hasNullStnkDoc)
    }

    if (itemCoas.length > 0) {
      const coaDocs = itemCoas.map((itemCoa) => itemCoa.coa)
      const hasNullCoaDoc = coaDocs.some((coaDoc) => coaDoc === '')
      setCoaDocFile(!hasNullCoaDoc)
    }
  }, [vehicles, itemCoas])

  const addItemCoas = (
    totalPack,
    totalQty,
    conversionUom,
    conversionQtyUom,
    conversionFactor
  ) => {
    // check batch exist or not
    const batchExist = itemCoas.some(
      (itemCoa) => itemCoa.batch.id === inputBatch.id
    )
    !batchExist
      ? setItemCoas([
          ...itemCoas,
          {
            id: itemCoas.length + 1,
            item_id: itemId,
            total_pack: parseInt(totalPack),
            total_qty: parseInt(totalQty),
            conversion_factor: conversionFactor,
            conversion_uom: conversionUom,
            conversion_qty_uom: conversionQtyUom,
            items: selectItem,
            batch: inputBatch,
            batch_id: batchId,
            coa: '',
            coa_pd: inputBatch?.newBatch ? inputBatch?.manufacturing_date : '',
            coa_ed: inputBatch?.newBatch ? inputBatch?.exp_date : '',
            label_pd: '',
            label_ed: '',
            area_id: null,
            remark: '',
            problem: null
          }
        ])
      : Common.showPopUpErrorMessage('Batch already in list')
  }

  function handleUpdateVehicle(item) {
    setModalUpdateShow(true)
    setSelectedVehicle(item)
  }

  function handleUpdateItemCoa(item) {
    setModalUpdateItemShow(true)
    setSelectedItemCoa(item)
  }

  function handleAddBatch(item) {
    setModalAddBatch(true)
  }

  function handleAddCategory(item) {
    setModalAddCategory(true)
    setListItemsId(item)
  }

  const updateVehicleInList = (updatedVehicle) => {
    const updatedVehicles = vehicles.map((vehicle) =>
      vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle
    )
    setVehicles(updatedVehicles)
  }

  const updateItemCoasInList = (updatedItemCoas) => {
    const updatedItemCoass = itemCoas.map((itemCoa) =>
      itemCoa.id === updatedItemCoas.id ? updatedItemCoas : itemCoa
    )
    setItemCoas(updatedItemCoass)
  }

  function handleClickItem(item) {
    setSelectItem(item)
    items.map((b, i) => {
      b.id === item.id && setDataItemSelect(b)
    })
    setItemId(item.id)
    if (item.id !== '0') {
      try {
        api.get(`inbound/pr-draft/show-batches?item_id=${item.id}`)
      } catch (err) {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  function onDeleteItemCoa(item) {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const index = itemCoas.findIndex((coa) => coa.id === item.id)
          if (index !== -1) {
            const updatedItemCoas = [...itemCoas]
            updatedItemCoas.splice(index, 1)
            setItemCoas(updatedItemCoas)
          }
        } catch (err) {
          const errMsg = err?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  function onDeleteItemVehicle(item) {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const index = vehicles.findIndex((vehicle) => vehicle.id === item.id)
          if (index !== -1) {
            const updatedVehicle = [...vehicles]
            updatedVehicle.splice(index, 1)
            setVehicles(updatedVehicle)
          }
        } catch (err) {
          const errMsg = err?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const handleSearch = async (query) => {
    const res = await api.get(`inbound/pr-draft/show-batches?item_id=${itemId}`)
    setOptions(res.data.data)
    setTimeout(() => {
      setNewOption({ batch: query })
    }, 1000)
  }

  const getWarehouses = async (branch) => {
    try {
      const params = {
        branch
      }
      const res = await api.get('/inbound/pr-draft/accepted-warehouses', {
        params
      })
      if (res.status === 200) {
        let options = res.data.data
        if (method === 'cross_dock') {
          options = res.data.data.filter((data) => !data.is_external_warehouse)
        }
        const optionData = options.reduce((acc, data) => {
          acc.push({ value: data.id, label: data.warehouse_id })
          return acc
        }, [])
        setWarehouses(optionData)
        setDataWarehouses(res.data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const renderMenuItemChildren = (option) => (
    <div>
      {option.isNew ? (
        <>
          <h6 onClick={() => handleAddBatch(option)}>
            <FontAwesomeIcon icon={faPlus} className="me-1" /> NEW BATCH
          </h6>
        </>
      ) : (
        <span>{option.batch}</span>
      )}
    </div>
  )

  const allOptions = [
    ...options,
    ...(newOption ? [{ ...newOption, isNew: true }] : [])
  ]

  const refBatch = useRef(AsyncTypeahead)
  const refItems = useRef(null)

  const fetchSeries = async () => {
    try {
      const res = await api.get(`inbound/pr-draft/show-series?po=${code}`)
      const { data } = res.data
      const series = data.map((item) => ({
        ...item,
        id: item.name,
        value: item.name
      }))
      setSeries(series)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const removeWhiteSpace = (value) => {
    return value.trim()
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px'
    })
  }

  const loadOptions = useCallback(
    async (inputValue = '') => {
      try {
        setLoadingArea(true)
        const res = await api.get('/master/area/all', {
          params: {
            q: inputValue,
            id:
              dataWarehouses.find(
                (dataWarehouse) => dataWarehouse.id === selectedWarehouse
              )?.warehouse_id || ''
          }
        })

        if (res.data.success) {
          const dataCoa =
            itemCoas?.find((itemCoa) => itemCoa.id === itemCoaIdValue) || ''
          const coaMatch = dataCoa
            ? dataCoa.coa_pd === dataCoa.label_pd &&
              dataCoa.coa_ed === dataCoa.label_ed
            : true

          const filteredData = res.data.data
            .filter((data) => (coaMatch ? data : data.code !== 'UT')) // Only exclude 'UT' if not matching
            .map((cur) => ({
              value: cur.id,
              label: cur.name,
              code: cur.code
            }))

          return filteredData
        }
      } catch (err) {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
        console.error('Error loading options:', err)
      } finally {
        setLoadingArea(false)
      }
    },
    [dataWarehouses, itemCoaIdValue, selectedWarehouse, itemCoas]
  )

  const handleChangeItemCoa = (type, itemCoaId, value) => {
    setItemCoas((prevItemCoas) =>
      prevItemCoas.map((itemCoasMap) => {
        if (itemCoasMap.id === itemCoaId) {
          if (type === 'area_id') {
            const warehouseCode =
              dataWarehouses.find(
                (dataWarehouse) => dataWarehouse.value === value.value
              )?.code || null
            return {
              ...itemCoasMap,
              [type]: value,
              problem: warehouseCode === 'UT' ? '' : itemCoasMap.problem,
              remark: warehouseCode === 'UT' ? '' : itemCoasMap.remark
            }
          }
          return {
            ...itemCoasMap,
            [type]: value
          }
        }
        return itemCoasMap
      })
    )
  }

  const variantButtonSubmit = useCallback(
    (dirty, valid) => {
      if (method === 'cross_dock') {
        if (!dirty) {
          return 'secondary'
        }
        if (
          !valid ||
          !itemCoas.length ||
          itemCoas.some((itemCoa) => itemCoa.coa === '')
        ) {
          return 'secondary'
        }
      } else {
        if (!dirty) {
          return 'secondary'
        }
        if (
          (!simDocFile && !stnkDocFile) ||
          !valid ||
          !itemCoas.length ||
          (itemCoas.length > 0 &&
            itemCoas.filter((itemCoa) => itemCoa.coa === '').length > 0)
        ) {
          return 'secondary'
        }
        if (
          typeWarehouseExternal &&
          (attachments.length === 0 ||
            !itemCoas.length ||
            itemCoas.filter(
              (itemCoa) =>
                !itemCoa.area_id ||
                itemCoa.coa_pd === '' ||
                itemCoa.coa_ed === '' ||
                itemCoa.label_pd === '' ||
                itemCoa.label_ed === '' ||
                (itemCoa.area_id.code !== 'UT' && itemCoa.remark === '')
            )?.length > 0)
        ) {
          return 'secondary'
        }
      }

      return 'warning'
    },
    [
      method,
      itemCoas,
      simDocFile,
      stnkDocFile,
      coaDocFile,
      typeWarehouseExternal,
      attachments
    ]
  )

  useEffect(() => {
    loadData()
    loadItems()
    fetchSeries()
  }, [])

  useEffect(() => {
    if (itemCoaIdValue) {
      loadOptions()
        .then((options) => setAreaOptions(options))
        .catch((err) => console.error('Error in loadOptions:', err))
    }
  }, [itemCoaIdValue, loadOptions])

  if (isLoadingPage) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md={12} className="mt-3">
            <h4 className="text-warning">Create PR Draft</h4>
          </Col>
          <Col md={12} className="">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <small>
                    <strong
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      onClick={() => navigate('/purchase-order')}
                    >
                      PURCHASE ORDER
                    </strong>
                  </small>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <small>CREATE PR DRAFT</small>
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              do: '',
              pib_date: '',
              expedition: '',
              aju: '',
              pic_number: '',
              pr_type: pr_type ? pr_type : poData.type,
              vehicle: '',
              total_pack: '',
              series_number: ''
            }}
            enableReinitialize={true}
            validateOnChange={true}
            validationSchema={
              pr_type === 'import'
                ? PurchaseReceiptCreateImportSchema
                : PurchaseReceiptCreateLocalSchema
            }
            onSubmit={(values) => {
              handleSubmit(values)
            }}
            context={{ pr_type }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
              setFieldValue,
              setFieldTouched
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-4">
                    <TextField
                      label="#po"
                      name="po_name"
                      value={poData.po_name}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="po type"
                      name="po_type"
                      value={pr_type ? pr_type : poData.type}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="branch"
                      name="po_branch"
                      value={poData.branch}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="supplier"
                      name="po_supplier"
                      value={poData.supplier}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <SelectInput
                      label="SERIES"
                      name="series_number"
                      id="series_number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={series}
                      required={true}
                      col={3}
                      value={values.series_number}
                      placeholder="Select Series"
                    />
                    <div className="col-3">
                      <FormLabel className="text-muted text-uppercase fw-bold">
                        Accepted Warehouse
                      </FormLabel>
                      <Select
                        isClearable
                        defaultValue={selectedWarehouse}
                        styles={customStyles}
                        onChange={(e) => {
                          if (!e) {
                            e = {
                              target: refSrcWarehouses,
                              value: ''
                            }
                          }
                          setSelectedWarehouse(e?.value)
                          setTypeWarehouseExternal(
                            dataWarehouses.find((item) => item.id === e?.value)
                              .is_external_warehouse || false
                          )
                        }}
                        options={warehouses}
                        isSearchable
                        ref={refSrcWarehouses}
                      />
                    </div>
                    <TextField
                      label="method"
                      name="method"
                      value={transformKey(method)}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                  </Row>
                  {/* ARRIVAL */}
                  <Row className="mb-3">
                    <Col md={12} className="mb-2">
                      <h5 className="text-secondary">
                        ARRIVAL INFO
                        <FontAwesomeIcon
                          icon={icon}
                          className="ms-3"
                          onClick={() => {
                            setOpen(!open)
                            open === true
                              ? setIcon(faCaretDown)
                              : setIcon(faCaretUp)
                          }}
                          aria-expanded={open}
                          aria-controls="example-collapse-text"
                          style={{ cursor: 'pointer' }}
                        />
                      </h5>
                    </Col>
                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <Row className="mb-3">
                          <TextField
                            label="#do"
                            name="do"
                            value={values.do}
                            handleChange={(e) => {
                              const capitalizedValue = capitalizeWords(
                                e.target.value
                              )
                              setFieldValue('do', capitalizedValue)
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input do"
                            col={3}
                            bold
                            required
                          />
                          <TextField
                            label="expedition"
                            name="expedition"
                            value={values.expedition}
                            handleChange={(e) => {
                              const capitalizedValue = capitalizeWords(
                                e.target.value
                              )
                              setFieldValue('expedition', capitalizedValue)
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input expedition"
                            col={3}
                            bold
                            required
                          />
                          {pr_type === 'import' && (
                            <>
                              <DatePickerComponent
                                label="pib date"
                                name="pib_date"
                                value={values.pib_date}
                                handleChange={setFieldValue}
                                handleBlur={() =>
                                  setFieldTouched('pib_date', true)
                                }
                                errors={errors}
                                touched={touched}
                                col={3}
                                required
                              />
                              <TextField
                                label="#aju"
                                name="aju"
                                value={values.aju}
                                handleChange={(e) => {
                                  const capitalizedValue = capitalizeWords(
                                    e.target.value
                                  )
                                  setFieldValue('aju', capitalizedValue)
                                }}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                col={3}
                                bold
                                required
                                placeholder="Input aju"
                              />
                            </>
                          )}
                          <TextField
                            label="pic name"
                            name="pic_name"
                            value={values.pic_name}
                            handleChange={(e) => {
                              const capitalizedValue = capitalizeWords(
                                e.target.value
                              )
                              setFieldValue('pic_name', capitalizedValue)
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            col={3}
                            bold
                            required
                            placeholder="Input pic name"
                          />
                          <TextField
                            label="pic number"
                            name="pic_number"
                            value={values.pic_number}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            type="number"
                            col={3}
                            bold
                            required
                            placeholder="Input pic number"
                          />
                        </Row>
                      </div>
                    </Collapse>
                  </Row>
                  {/* VEHICLE */}
                  {method !== 'cross_dock' && (
                    <Row className="mb-2">
                      <Col md={12} className="mb-3">
                        <h5 className="text-secondary">
                          VEHICLE
                          <FontAwesomeIcon
                            icon={iconVehicle}
                            className="ms-3"
                            onClick={() => {
                              setOpenVehicle(!openVehicle)
                              openVehicle === true
                                ? setIconVehicle(faCaretDown)
                                : setIconVehicle(faCaretUp)
                            }}
                            aria-expanded={openVehicle}
                            aria-controls="example-collapse-text1"
                            style={{ cursor: 'pointer' }}
                          />
                        </h5>
                      </Col>
                      <Collapse in={openVehicle}>
                        <div id="example-collapse-text1">
                          <Row>
                            <Col md={10}>
                              <TextField
                                label="plate no."
                                name="vehicle"
                                value={values.vehicle}
                                handleChange={(e) => {
                                  const capitalizedValue = capitalizeWords(
                                    e.target.value
                                  )
                                  const removeWhiteSpaceValue =
                                    removeWhiteSpace(capitalizedValue)
                                  setFieldValue(
                                    'vehicle',
                                    removeWhiteSpaceValue
                                  )
                                }}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                bold
                                required
                                placeholder="Input vehicle"
                              />
                            </Col>
                            <Col md={2} className="text-end ">
                              <Button
                                type="button"
                                className="btn btn-primary-blue w-100"
                                style={{ marginTop: '25px' }}
                                onClick={() => {
                                  addVehicle(values.vehicle)
                                  setFieldValue('vehicle', '')
                                }}
                                disabled={!values.vehicle}
                              >
                                Add Vehicle
                              </Button>
                            </Col>
                            <Col md={12} className="mt-3">
                              {vehicles.length > 0 && (
                                <Table
                                  hover
                                  responsive="sm"
                                  className="shadow-sm mb-5 bg-white rounded"
                                  size="sm"
                                  style={{ fontSize: '12px' }}
                                >
                                  <thead className="table-secondary">
                                    <tr>
                                      <th className="p-2 align-middle">No</th>
                                      <th
                                        className="p-2"
                                        style={{ width: '30%' }}
                                      >
                                        Plate No
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        SIM
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        Validity
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        Notes
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        STNK
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        Validity
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        Notes
                                      </th>
                                      <th className="p-2 text-end align-middle">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {vehicles.map((vehicle, index) => (
                                      <tr key={index}>
                                        <td className="p-2 align-middle">
                                          {index + 1}
                                        </td>
                                        <td className="p-2 align-middle">
                                          {vehicle.plate_no
                                            ? vehicle.plate_no.toUpperCase()
                                            : '-'}
                                        </td>
                                        <td className="p-2 text-end align-middle text-primary">
                                          {vehicle.sim_doc.name || '-'}
                                        </td>
                                        <td className="p-2 text-end align-middle">
                                          {vehicle.sim_validity === null
                                            ? '-'
                                            : vehicle.sim_validity === true
                                            ? 'Valid'
                                            : 'Invalid'}
                                        </td>
                                        <td className="p-2 text-end align-middle">
                                          {vehicle.sim_notes || '-'}
                                        </td>
                                        <td className="p-2 text-end align-middle text-primary">
                                          {vehicle.stnk_doc.name || '-'}
                                        </td>
                                        <td className="p-2 text-end align-middle">
                                          {vehicle.stnk_validity === null
                                            ? '-'
                                            : vehicle.stnk_validity === true
                                            ? 'Valid'
                                            : 'Invalid'}
                                        </td>
                                        <td className="p-2 text-end align-middle">
                                          {vehicle.stnk_notes || '-'}
                                        </td>
                                        <td className="p-2 text-end align-middle">
                                          <div className="d-flex flex-row gap-2 justify-content-end">
                                            <div className="d-flex flex-column justify-content-center">
                                              <FontAwesomeIcon
                                                icon={faCircleExclamation}
                                                className="text-danger me-2"
                                                style={{ fontSize: '16px' }}
                                                hidden={
                                                  vehicle.sim_doc !== '' &&
                                                  vehicle.stnk_doc !== ''
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <button
                                              className="btn btn-secondary ml-2"
                                              type="button"
                                              onClick={() =>
                                                handleUpdateVehicle(vehicle)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faPenToSquare}
                                              />
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-secondary ml-2"
                                              onClick={() =>
                                                onDeleteItemVehicle(vehicle)
                                              }
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </Row>
                  )}
                  {/* ITEM & COA */}
                  <Row>
                    <Col md={12} className="mb-1">
                      <h5 className="text-secondary">
                        ITEM & COA
                        <FontAwesomeIcon
                          icon={iconItemCoa}
                          className="ms-3"
                          onClick={() => {
                            setOpenItemCoa(!openItemCoa)
                            openItemCoa === true
                              ? setIconItemCoa(faCaretDown)
                              : setIconItemCoa(faCaretUp)
                          }}
                          aria-expanded={openItemCoa}
                          aria-controls="example-collapse-text2"
                          style={{ cursor: 'pointer' }}
                        />
                      </h5>
                    </Col>
                    <Collapse in={openItemCoa}>
                      <div id="example-collapse-text2">
                        <Row className="mb-1">
                          <SelectInput
                            label="item"
                            name="item_id"
                            id="item_id"
                            handleChange={(e) => {
                              const selectedOption =
                                e.target.options[e.target.selectedIndex]
                              const selectedHasCategory = parseInt(
                                selectedOption.getAttribute('hasData')
                              )
                              return handleClickItem({
                                id: e.target.value,
                                name: e.target.options[e.target.selectedIndex]
                                  .text,
                                has_category: selectedHasCategory
                              })
                            }}
                            reff={refItems}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            options={items}
                            required
                            hasData
                            col={4}
                            bold
                          />
                          <Form.Group className="col-12 col-md-2 col-lg-2">
                            <Form.Label>
                              <small>
                                <strong>
                                  BATCH NUMBER
                                  <sup className="text-danger">*</sup>
                                </strong>
                              </small>
                            </Form.Label>
                            <AsyncTypeahead
                              id="async-typeahead-example"
                              labelKey="batch"
                              onSearch={handleSearch}
                              options={allOptions}
                              renderMenuItemChildren={renderMenuItemChildren}
                              onChange={(e) => {
                                setInputBatch(e.length > 0 ? e[0] : '')
                                setBatchId(e.length > 0 ? e[0].id : 0)
                              }}
                              inputProps={{
                                value: inputBatch,
                                className: styles.input
                              }}
                              placeholder="Input"
                              disabled={
                                itemId === '0' || itemId === 0 ? true : false
                              }
                              ref={refBatch}
                            />
                          </Form.Group>
                          <TextField
                            label="total pack"
                            name="total_pack"
                            value={values.total_pack}
                            handleChange={(e) => {
                              handleChange(e)
                              setTotalPack((prevData) => ({
                                ...prevData,
                                total_qty:
                                  e.target.value *
                                  dataItemSelect.uom.conversion_factor,
                                conversion_uom:
                                  dataItemSelect.uom.conversion_uom,
                                conversion_qty_uom:
                                  dataItemSelect.uom.conversion_qty_uom,
                                conversion_factor:
                                  dataItemSelect.uom.conversion_factor
                              }))
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            col={2}
                            bold
                            required
                            placeholder="Input"
                            disabled={
                              itemId === '0' || itemId === 0 ? true : false
                            }
                          />
                          <TextField
                            label="total qty"
                            name="total_qty"
                            value={totalPack.total_qty}
                            touched={touched}
                            errors={errors}
                            col={2}
                            bold
                            required
                            disabled
                          />
                          <Col md={2} className="text-end">
                            <Button
                              className="btn btn-primary-blue w-100"
                              style={{ marginTop: '25px' }}
                              onClick={() => {
                                addItemCoas(
                                  values.total_pack,
                                  totalPack.total_qty,
                                  totalPack.conversion_uom,
                                  totalPack.conversion_qty_uom,
                                  totalPack.conversion_factor
                                )
                                setFieldValue('total_pack', '')
                                refBatch.current?.clear()
                                refItems.current.value = ''
                                setTotalPack({
                                  total_qty: 0,
                                  conversion_uom: '',
                                  conversion_qty_uom: '',
                                  conversion_factor: ''
                                })
                              }}
                              disabled={!values.total_pack}
                            >
                              Add Item
                            </Button>
                          </Col>
                          <Col md={12} className="mt-3">
                            {itemCoas.length > 0 && (
                              <Table
                                hover
                                responsive="sm"
                                className="shadow-sm mb-5 bg-white rounded"
                                size="sm"
                                style={{ fontSize: '12px' }}
                              >
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="p-2 align-middle">No</th>
                                    <th
                                      className="p-2 align-middle"
                                      style={{ width: '20%' }}
                                    >
                                      Item
                                    </th>
                                    {!typeWarehouseExternal &&
                                      method !== 'cross_dock' && (
                                        <th className="p-2 text-end align-middle">
                                          Status COA
                                        </th>
                                      )}
                                    <th className="p-2 text-end align-middle">
                                      Batch
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Total Pack
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Total Qty
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      COA Document
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      COA MD
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      COA ED
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Label MD
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Label ED
                                    </th>
                                    {typeWarehouseExternal && (
                                      <>
                                        <th className="p-2 text-end align-middle">
                                          Destination Area
                                        </th>
                                        <th className="p-2 text-end align-middle">
                                          Problem
                                        </th>
                                        <th className="p-2 text-end align-middle">
                                          Remark
                                        </th>
                                      </>
                                    )}
                                    <th className="p-2 text-end align-middle">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {itemCoas.map((item, i) => {
                                    return (
                                      <>
                                        <tr key={i}>
                                          <td className="p-2 align-middle">
                                            {i + 1}
                                          </td>
                                          <OverlayTrigger
                                            trigger={['hover', 'click']}
                                            key={'bottom'}
                                            placement={'bottom'}
                                            overlay={
                                              <Popover
                                                id={`popover-positioned-bottom`}
                                              >
                                                <Popover.Body>
                                                  <p className="text-center fw-bold text-uppercase">
                                                    item name
                                                  </p>
                                                  <p className="text-center">
                                                    {item?.items?.name}
                                                  </p>
                                                  <p className="text-center fw-bold text-uppercase">
                                                    item code
                                                  </p>
                                                  <p className="text-center">
                                                    {item?.items?.id}
                                                  </p>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <td className="p-2 align-middle">
                                              <Row>
                                                <Col md={12}>
                                                  {item?.items?.name}
                                                </Col>
                                                {items?.find(
                                                  (itemValue) =>
                                                    itemValue?.id ===
                                                    item?.items?.id
                                                )?.has_category === 0 ? (
                                                  <Col md={12}>
                                                    <small
                                                      className="text-danger fw-bold"
                                                      onClick={() =>
                                                        handleAddCategory(
                                                          item.items
                                                        )
                                                      }
                                                      style={{
                                                        cursor: 'pointer'
                                                      }}
                                                    >
                                                      ! Uncategorized Item
                                                    </small>
                                                  </Col>
                                                ) : null}
                                              </Row>
                                            </td>
                                          </OverlayTrigger>
                                          {!typeWarehouseExternal &&
                                            method !== 'cross_dock' && (
                                              <td className="p-2 text-end align-middle">
                                                <Badge pill bg="secondary">
                                                  UNCHECKED
                                                </Badge>
                                              </td>
                                            )}
                                          <td className="p-2 text-end align-middle">
                                            {item?.batch?.batch}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.total_pack}{' '}
                                            {item?.conversion_uom}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.total_qty}{' '}
                                            {item?.conversion_qty_uom}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.coa?.name
                                              ? item?.coa?.name
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item.coa_pd
                                              ? convertDateWithoutTime(
                                                  item.coa_pd
                                                )
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item.coa_ed
                                              ? convertDateWithoutTime(
                                                  item.coa_ed
                                                )
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {typeWarehouseExternal ||
                                            method === 'cross_dock'
                                              ? item.label_pd &&
                                                item.label_pd !== ''
                                                ? convertDateWithoutTime(
                                                    item.label_pd
                                                  )
                                                : '-'
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {typeWarehouseExternal ||
                                            method === 'cross_dock'
                                              ? item.label_ed &&
                                                item.label_ed !== ''
                                                ? convertDateWithoutTime(
                                                    item.label_ed
                                                  )
                                                : '-'
                                              : '-'}
                                          </td>
                                          {typeWarehouseExternal && (
                                            <>
                                              <td className="p-2 text-end align-middle">
                                                <Select
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  cacheOptions
                                                  options={areaOptions}
                                                  onMenuOpen={() => {
                                                    setItemCoaIdValue(item?.id)
                                                  }}
                                                  onMenuClose={() => {
                                                    setItemCoaIdValue(null)
                                                  }}
                                                  onChange={(e) =>
                                                    handleChangeItemCoa(
                                                      'area_id',
                                                      item?.id,
                                                      e
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td className="p-2 text-end align-middle">
                                                <Form.Select
                                                  aria-label="select-problem"
                                                  onChange={(e) =>
                                                    handleChangeItemCoa(
                                                      'problem',
                                                      item?.id,
                                                      e.target.value || ''
                                                    )
                                                  }
                                                  disabled={
                                                    !item.area_id ||
                                                    item.area_id.code === 'UT'
                                                  }
                                                  value={item?.problem || ''}
                                                >
                                                  {item?.coa_pd ===
                                                    item?.label_pd &&
                                                  item?.coa_ed ===
                                                    item?.label_ed ? (
                                                    <>
                                                      <option>
                                                        Select Problem
                                                      </option>
                                                      <option value="bocor">
                                                        Bocor
                                                      </option>
                                                      <option value="tidak_ada_label">
                                                        Tidak Ada Label
                                                      </option>
                                                      <option value="tidak_ada_segel">
                                                        Tidak Ada Segel
                                                      </option>
                                                    </>
                                                  ) : (
                                                    <option value="coa_not_match">
                                                      COA NOT MATCH
                                                    </option>
                                                  )}
                                                </Form.Select>
                                              </td>
                                              <td className="p-2 text-end align-middle">
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Ketik Keterangan"
                                                  value={item?.remark || ''}
                                                  onChange={(e) =>
                                                    handleChangeItemCoa(
                                                      'remark',
                                                      item?.id,
                                                      e.target.value || ''
                                                    )
                                                  }
                                                  disabled={
                                                    !item.area_id ||
                                                    item.area_id.code === 'UT'
                                                  }
                                                />
                                              </td>
                                            </>
                                          )}
                                          <td className="p-2 text-end align-middle">
                                            <div className="d-flex flex-row gap-2 justify-content-end">
                                              <div className="d-flex flex-column justify-content-center">
                                                <FontAwesomeIcon
                                                  icon={faCircleExclamation}
                                                  className="text-danger me-2"
                                                  style={{ fontSize: '16px' }}
                                                  hidden={
                                                    item.coa_pd ===
                                                      item.label_pd &&
                                                    item.coa_ed ===
                                                      item.label_ed
                                                  }
                                                />
                                              </div>
                                              <button
                                                className="btn btn-secondary ml-2"
                                                type="button"
                                                onClick={() =>
                                                  handleUpdateItemCoa(item)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faPenToSquare}
                                                />
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-secondary ml-2"
                                                onClick={() =>
                                                  onDeleteItemCoa(item)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </Row>
                  {/* ATTACHMENT */}
                  {typeWarehouseExternal && (
                    <div className="d-flex flex-column gap-2 mb-5">
                      <Row>
                        <Col md={12} className="mb-3">
                          <h5 className="text-secondary text-uppercase">
                            attachment <sup style={{ color: 'red' }}>*</sup>
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5}>
                          <div
                            style={{
                              border: `1px dashed ${
                                isDragging ? 'gray' : '#C9CACD'
                              }`,
                              height: '80px'
                            }}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                          >
                            <div className="d-flex flex-column justify-content-center p-2 h-100">
                              <div className="d-flex flex-row justify-content-center gap-2">
                                <input
                                  type="file"
                                  id="upload-file-attachment"
                                  className="d-none"
                                  ref={uploadFileAttachment}
                                  onChange={(e) =>
                                    handleChangeUploadAttachment(e)
                                  }
                                  accept="application/pdf"
                                />
                                <Button
                                  style={btnOrange}
                                  onClick={() =>
                                    uploadFileAttachment.current?.click()
                                  }
                                  className="text-uppercase fw-bold"
                                >
                                  Browse
                                </Button>
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center h-100"
                                  style={{ fontSize: '13px' }}
                                >
                                  Or Drop pdf files here
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {attachments.length > 0 && (
                        <Row className="mt-3">
                          <Col md={5}>
                            <div className="d-flex flex-column gap-3">
                              {attachments.map((attachment, index) => (
                                <div
                                  className="d-flex justify-content-between px-2 py-3 rounded shadow-sm"
                                  style={{ border: '0.5px solid #E3E3E3' }}
                                  key={`attachment-${index}`}
                                >
                                  <span className="fs-6 mt-2 ms-2">
                                    {attachment?.name || ''}
                                  </span>

                                  <button
                                    type="button"
                                    className="btn btn-secondary me-3"
                                    onClick={() =>
                                      handleRemoveUploadAttachment(
                                        Number(index)
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                  {/* BUTTON */}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        <Button
                          className="text-danger"
                          type="button"
                          variant="link"
                          style={{ textDecoration: 'none' }}
                          onClick={() => {
                            Swal.fire({
                              title: 'Cancel creating ticket?',
                              text: 'Draft will be discarded',
                              icon: 'warning',
                              allowOutsideClick: false,
                              showCancelButton: true,
                              confirmButtonColor: '#d33',
                              cancelButtonText: 'Continue creating',
                              confirmButtonText: 'Discard'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate('/purchase-order')
                              }
                            })
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col xs={6} md={6} lg={6} className="text-end">
                        <CustomButton
                          onClick={handleSubmit}
                          type="submit"
                          variant={variantButtonSubmit(dirty, isValid)}
                          disabled={
                            variantButtonSubmit(dirty, isValid) === 'secondary'
                              ? true
                              : false
                          }
                          text={'Save'}
                        />
                      </Col>
                    </Row>
                  )}
                </Form>
              )
            }}
          </Formik>
        </Row>
      </Container>

      <ModalUpdateVehiclePrDraft
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        selectedVehicle={selectedVehicle}
        updateVehicleInList={updateVehicleInList}
      />

      <ModalAddBatch
        show={modalAddBatch}
        onHide={() => setModalAddBatch(false)}
        item={selectItem}
        batch={inputBatch}
        setBatchId={setBatchId}
        setInputBatch={setInputBatch}
        refreshData={() => refBatch.current?.clear()}
      />

      <ModalUpdateItemCoaPrDraft
        show={modalUpdateItemShow}
        onHide={() => setModalUpdateItemShow(false)}
        method={method}
        selecteditemcoa={selectedItemCoa}
        typeWarehouseExternal={typeWarehouseExternal}
        updateitemcoasinlist={updateItemCoasInList}
      />

      <ModalAddCategoryItem
        show={modalAddCategory}
        onHide={() => setModalAddCategory(false)}
        code={listItemsId.id}
        name={listItemsId.name}
        refreshData={() => {
          loadItems()
        }}
      />
    </>
  )
}

export default PurchaseReceiptCreate
