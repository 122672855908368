import React from 'react';

function LabelDetail(props) {
  const {header, value} = props;

  return (
    <div className="d-flex flex-column gap-2">
      <h6 className="fw-bold text-uppercase" style={{ fontSize: '13px' }}>{header}</h6>
      <p className="fw-bold" style={{ fontSize: '15px' }}>{value}</p>
    </div>
  );
}

export default LabelDetail;