import React, { useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { transformKey } from '../../helpers/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import * as Common from '../../utils/Common'
import { useNotification } from '../../context/NotificationProvider'
import Swal from 'sweetalert2'
import api from '../../config/AxiosInstance'
const FILE_SIZE = 5000 * 1024 // 5MB in bytes

function CreateMaterialIssue() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { showNotification, hideNotification } = useNotification();
  const uploadFileAttachment = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [data, setData] = useState({
    items: state.items,
    attachments: []
  })
  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (!file) {
      const errMsg = 'File attachment is required'
      Common.showPopUpErrorMessage(errMsg)
      setIsDragging(false)
      return
    }
    if (file.type !== 'application/pdf') {
      const errMsg = 'File attachment must be file PDF'
      Common.showPopUpErrorMessage(errMsg)
      setIsDragging(false)
      return
    }
    if (file.size > FILE_SIZE) {
      const errMsg = 'File attachment must be less than 5MB'
      Common.showPopUpErrorMessage(errMsg)
      return
    }
    setData((prevData) => ({
      ...prevData,
      attachments: [...prevData.attachments, file]
    }))
    event.dataTransfer.clearData()
  }
  const handleChangeUploadAttachment = (e) => {
    const file = e.target.files[0]
    if (!file) {
      const errMsg = 'File attachment is required'
      Common.showPopUpErrorMessage(errMsg)
      return
    }

    if (file.type !== 'application/pdf') {
      const errMsg = 'File attachment must be file PDF'
      Common.showPopUpErrorMessage(errMsg)
      return
    }

    if (file.size > FILE_SIZE) {
      const errMsg = 'File attachment must be less than 5MB'
      Common.showPopUpErrorMessage(errMsg)
      return
    }
    setData((prevData) => ({
      ...prevData,
      attachments: [...prevData.attachments, file]
    }))
  }
  const handleSubmitTicket = async () => {
    Swal.fire({
      html: `<div>
      <p style="color: gray">Create ticket?</p>
      <p style="color: gray">Make sure all info are correct!</p>
      </div>`,
      icon: 'warning',
      iconColor: '#828282',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Create',
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-cancel-pr'
      },
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            items: data.items.map((item) => ({
              slot_item_id: item.slot_item_id,
              bu: item.bu,
            })),
            attachment: data.attachments,
            warehouse_id: state.warehouse_id,
          }
          const res = await api.postForm("material-issue/new-ticket", payload);
          console.log(res);
          const { status } = res;
          if (status === 200) {
            showNotification("Material Issue created!", "success");
            navigate(-1);
          }
        } catch (err) {
          const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  };
  const handleRemoveUploadAttachment = (index) => {
    setData((prevData) => ({
      ...prevData,
      attachments: prevData.attachments.filter((_, i) => i !== index)
    }))
  }
  const handleDelete = (data) => {
    setData((prevData) => ({
      ...prevData,
      items: prevData.items.filter(
        (item) => item.slot_item_id !== data.slot_item_id
      )
    }))
  }
  const handlePressBack = () => {
    navigate(-1)
  }
  const handleChangeInput = (slotItemId, value) => {
    setData((prevData) => ({
      ...prevData,
      items: prevData.items.map((item) => {
        if (item.slot_item_id === slotItemId) {
          return {
            ...item,
            bu: value
          }
        }
        return item
      })
    }))
  }
  const handleButtonSubmit = () => {
    if (data.items.length === 0) {
      return 'btn-secondary';
    }
    if (data.items.every((item) => !item.bu || item.bu === '') || !data.attachments.length) {
      return 'btn-secondary';
    }
    return 'btn-primary-orange';
  }
  const columns = [
    {
      key: 'item_name',
      label: 'Item Name'
    },
    {
      key: 'batch',
      label: 'Batch'
    },
    {
      key: 'total_qty',
      label: 'Total Qty'
    },
    {
      key: 'total_pack',
      label: 'Total Pack',
      component: (data) => data.total_pack ?? '-'
    },
    {
      key: 'location',
      label: 'Location',
      component: (data) => data.location ?? '-'
    },
    {
      key: 'item_problem',
      label: 'Problem',
      component: (data) =>
        data.item_problem ? transformKey(data.item_problem) : '-'
    },
    {
      key: 'bu',
      label: 'BU',
      required: true,
      component: (data) => (
        <Form.Control
          type="text"
          value={data.bu || ''}
          onChange={(e) => handleChangeInput(data.slot_item_id, e.target.value)}
        ></Form.Control>
      )
    },
    {
      key: 'action',
      label: 'Action',
      className: 'text-end',
      component: (data) => (
        <div
          className="d-flex flex-row gap-2 justify-content-end"
          key={data.slot_item_id}
        >
          <Button
            variant="secondary"
            size="sm"
            className="text-uppercase"
            onClick={() => handleDelete(data)}
          >
            <FontAwesomeIcon icon={faTrash} style={{ color: 'white' }} />
          </Button>
        </div>
      )
    }
  ]
  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <h6 className="header-title-style" style={{ fontSize: '20px' }}>
                  Create Material Issue
                </h6>
              </div>
              <div className="d-flex justify-content-end gap-2">
                <Button
                  variant="danger"
                  style={{ height: '35px' }}
                  onClick={() => handlePressBack()}
                >
                  Cancel
                </Button>
                <Button
                  className={handleButtonSubmit()}
                  style={{ height: '35px' }}
                  disabled={handleButtonSubmit() === 'btn-secondary'}
                  onClick={() => handleSubmitTicket()}
                >
                  Create Ticket
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-2 me-2 my-3">
          <Table>
            <thead className="table-secondary">
              <tr>
                {columns.map((column) => (
                  <th
                    className={`${column.className ?? ''} align-middle p-3`}
                    key={column.key}
                  >
                    <span>
                      {column.label}{' '}
                      {column.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.items.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="p-3 text-center">
                    No data
                  </td>
                </tr>
              )}
              {data.items.length > 0 &&
                data.items.map((item) => (
                  <tr key={item.slot_item_id}>
                    {columns.map((column) => (
                      <td
                        key={column.key}
                        className={`p-3 align-middle ${column.className ?? ''}`}
                      >
                        {column.component && column.component(item)}
                        {!column.component && item[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
        <Row>
          <div className="d-flex flex-column gap-2 mb-5">
            <Row>
              <Col md={12} className="mb-3">
                <h5 className="text-secondary text-uppercase">
                  attachment <sup style={{ color: 'red' }}>*</sup>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <div
                  style={{
                    border: `1px dashed ${isDragging ? 'gray' : '#C9CACD'}`,
                    height: '80px'
                  }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div className="d-flex flex-column justify-content-center p-2 h-100">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <input
                        type="file"
                        id="upload-file-attachment"
                        className="d-none"
                        ref={uploadFileAttachment}
                        onChange={(e) => handleChangeUploadAttachment(e)}
                        accept="application/pdf"
                      />
                      <Button
                        onClick={() => uploadFileAttachment.current?.click()}
                        className="text-uppercase fw-bold btn-primary-orange"
                      >
                        Browse
                      </Button>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center h-100"
                        style={{ fontSize: '13px' }}
                      >
                        Or Drop pdf files here
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {data.attachments.length > 0 && (
              <Row className="mt-3">
                <Col md={5}>
                  <div className="d-flex flex-column gap-3">
                    {data.attachments.map((attachment, index) => (
                      <div
                        className="d-flex justify-content-between px-2 py-3 rounded shadow-sm"
                        style={{ border: '0.5px solid #E3E3E3' }}
                        key={`attachment-${index}`}
                      >
                        <span className="fs-6 mt-2 ms-2">
                          {attachment?.name || ''}
                        </span>

                        <button
                          type="button"
                          className="btn btn-secondary me-3"
                          onClick={() =>
                            handleRemoveUploadAttachment(Number(index))
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default CreateMaterialIssue
