import {
  faCaretDown,
  faCaretUp,
  faCircleExclamation,
  faClockRotateLeft,
  faFileDownload,
  faPenToSquare,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {
  BadgeStatusCOA,
  BadgeStatusPrLists
} from '../../../components/atoms/StatusLabel/index'
import TextField from '../../../components/Input/TextInput'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import ModalPrDetailTimeline from '../../../components/modal/pr/ModalPrDetailTimeline'
import ModalUpdateItemCoaPrDraftDetail from '../../../components/modal/pr/ModalUpdateItemCoaPrDraftDetail'
import ModalUpdateVehiclePrDraft from '../../../components/modal/pr/ModalUpdateVehiclePrDraft'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime,
  transformKey
} from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { PurchaseReceiptCreateImportSchema } from '../../../utils/validationSchema'
import TableItemReceiptDetails from './tableItem'
import CardInfo from '../../../components/molecules/CardInfo'

function PurchaseReceiptDetail() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(true)
  const [icon, setIcon] = useState(faCaretUp)
  const [isLoading, setIsLoading] = useState(false)
  const [openVehicle, setOpenVehicle] = useState(true)
  const [iconVehicle, setIconVehicle] = useState(faCaretUp)
  const [openItemCoa, setOpenItemCoa] = useState(true)
  const [iconItemCoa, setIconItemCoa] = useState(faCaretUp)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [modalTimeline, setModalTimeline] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(0)
  const [detail, setDetail] = useState({})
  const [mapVehicles, setMapVehicles] = useState([])
  const [differentDate, setDifferentDate] = useState()
  const [detailInbounds, setDetailInbounds] = useState({})
  const [assignArea, setAssignArea] = useState([])
  const [assignAreaId, setAssignAreaId] = useState([])
  const [inboundId, setInboundId] = useState()
  const [areaData, setAreaData] = useState([])
  const [loadingArea, setLoadingArea] = useState(false)
  const [modalUpdateItemCoa, setModalUpdateItemCoa] = useState(false)
  const [selectedItemCoa, setSelectedItemCoa] = useState({})
  const [conditionItemInbound, setConditionItemInbound] = useState({
    inputValue: '',
    condition: ''
  })

  const { id } = useParams()

  useEffect(() => {
    loadData(id)
  }, [id])

  useEffect(() => {
    if (conditionItemInbound?.condition) {
      loadFocusOptions(
        conditionItemInbound?.inputValue,
        conditionItemInbound?.condition
      )
    }
  }, [conditionItemInbound])

  const loadData = async (id) => {
    try {
      setIsLoading(true)
      let coaNotMatch = false
      const res = await api.get(`inbound/pr-draft/${id}`)
      const { data } = res.data
      setDetail(data)
      const dataVehicles = data.vehicles.map((data) => {
        return {
          ...data
        }
      })

      data.items_coa.map((data, i) => {
        const test = data.coa_ed === data.label_ed
        setDifferentDate(test)
      })
      if (
        data?.items_coa.filter(
          (item_coa) =>
            item_coa?.coa_status === 'not_match' ||
            item_coa?.coa_status === 'not match'
        )?.length > 0
      ) {
        coaNotMatch = true
      }
      setMapVehicles(dataVehicles)

      if (res.data.success) {
        const dataMaster = await api.get(
          `master/area/all?id=${res?.data?.data?.po_info?.dest_warehouse_id}`
        )
        const { data } = dataMaster.data
        setAssignArea(data)
        // create object temporary to assign area id state

        const objTmpAssignArea = res.data.data.inbounds
          .reduce((acc, cur) => {
            let objParentAssignArea = null
            if (cur.status === 'waiting_om') {
              objParentAssignArea = {
                inbound_id: cur.id,
                areas:
                  cur.items.filter(
                    (item) => item.condition === 'damaged' && !item?.area_id
                  )?.length > 0 || coaNotMatch
                    ? []
                    : cur.items
                        .filter(
                          (x) =>
                            x.condition === 'good' ||
                            x.area === 'Area Gudang Karantina'
                        )
                        .reduce((accItem, curItem) => {
                          accItem.push({
                            item_staging_area_id: curItem.id,
                            area_id: curItem.area_id,
                            note: curItem.note || '',
                            is_rejected: [
                              'Area Gudang Utama',
                              'Area Gudang Karantina'
                            ].includes(curItem.area)
                              ? false
                              : true
                          })
                          return accItem
                        }, [])
              }
            }
            acc.push(objParentAssignArea)
            return acc
          }, [])
          .filter((x) => x !== null)
        if (objTmpAssignArea.length > 0) {
          setAssignAreaId(objTmpAssignArea)
        }
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Send to ERP?',
      text: `This action can't be repeated!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Send'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await api.postForm(`/inbound/pr-draft/send-erp/${id}`)
          if (res.data.success) {
            Common.showPopUpSuccessMessage(res.data.message)
            navigate('/inbound/purchase-receipt')
          }
        } catch (err) {
          const errMsg =
            err?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const addVehicle = (values, resetForm) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will also create new inbound document. Proceed ? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Yes, add it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const isPlateNoExists = vehicles.some(
            (vehicle) => vehicle.plate_no === values
          )
          if (isPlateNoExists) {
            Common.showPopUpErrorMessage('Plate No already in list')
            return
          }
          const body = {
            pr_id: id,
            plate_no: values.toUpperCase()
          }
          const newVehicleRes = await api.post('inbound/pr-draft/vehicle', body)
          const data = newVehicleRes.data.data
          setMapVehicles([...mapVehicles, data])
          resetForm({
            values: {
              plate_no: ''
            }
          })
          loadData(id)
        } catch (err) {
          const errMsg = err?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const [newDataVehicles, setNewDataVehicles] = useState([])

  useEffect(() => {
    setNewDataVehicles([...mapVehicles])
  }, [mapVehicles])

  function handleUpdateVehicle(item) {
    setModalUpdateShow(true)
    setSelectedVehicle(item)
  }

  const [dataHistory, setDataHistory] = useState({})

  async function handleTimline(item) {
    setModalTimeline(true)
    setDetailInbounds(item)
    const res = await api.get(`inbound/pr-draft/history/${item.id}`)
    setDataHistory(res.data)
  }

  function onDeleteItemVehicle(item) {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await api.delete(`inbound/pr-draft/vehicle/${item}`)
          if (res.data.success) {
            Common.showPopUpSuccessMessage(res.data.message)
            loadData(id)
          }
        } catch (err) {
          const errMsg = err?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const loadOptions = async (inputValue, condition) => {
    try {
      if (areaData.length > 0) {
        // first override to empty array
        setAreaData([])
      }
      setLoadingArea(true)
      const res = await api.get('/master/area/all', {
        params: {
          q: inputValue,
          id: detail?.po_info?.dest_warehouse_id,
          condition: condition === 'good' ? '' : condition
        }
      })

      if (res.data.success) {
        const options = res.data.data.reduce(
          (acc, data) => {
            acc.unshift({
              value: data.id,
              label: `${data.name} ${
                data.has_slot ? '' : ` - Slot Unavailable!`
              }`,
              hasSlot: data.has_slot,
              code: data.code,
              is_reject: false
            })
            return acc
          },
          [{ value: null, label: 'TOLAK', hasSlot: false, is_reject: true }]
        )
        setAreaData(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingArea(false)
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
      color: !state.data.hasSlot ? '#C12727' : 'black',
      backgroundColor: 'transparent',
      fontWeight: !state.data.is_reject ? 'normal' : 'bold'
    })
  }

  const loadFocusOptions = (inputValue, condition) => {
    loadOptions(inputValue, condition)
  }

  const handleSelectAreaItemInbound = (inboundId, itemStagingId, data) => {
    setAssignAreaId((assignAreaPrev) =>
      assignAreaPrev.map((inbound) => {
        if (inbound.inbound_id === inboundId) {
          // Check if the areas array already contains the itemStagingId
          const areaExists = inbound.areas.some(
            (area) => area.item_staging_area_id === itemStagingId
          )
          if (areaExists) {
            // Update the area if it exists
            return {
              ...inbound,
              areas: inbound.areas.map((area) =>
                area.item_staging_area_id === itemStagingId
                  ? {
                      ...area,
                      area_id: data.value,
                      is_rejected: data.is_reject
                    }
                  : area
              )
            }
          } else {
            // Add a new area if it doesn't exist
            return {
              ...inbound,
              areas: [
                ...inbound.areas,
                {
                  item_staging_area_id: itemStagingId,
                  area_id: data.value,
                  is_rejected: data.is_reject,
                  note: null
                }
              ]
            }
          }
        }
        return inbound
      })
    )
  }

  const handleSubmitAndSendErp = (inboundId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary-orange',
        cancelButton: 'btn btn-transparent',
        actions:
          'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Send to ERP?',
        text: 'This action can’t be repeated!',
        icon: 'warning',
        iconColor: '#828282',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send!',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        reverseButtons: true,
        showCloseButton: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            // read data value state assign area id
            if (!checkAssignArea(inboundId)) {
              Swal.close()
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Destination area is required'
              })
              return
            }
            const res = await api.post(
              `inbound/pr-draft/assign-area/${inboundId}`,
              {
                areas: assignAreaId.find(
                  (inbound) => inbound.inbound_id === inboundId
                )?.areas
              }
            )
            if (res.data.success) {
              Swal.close()
              // override state value assign area id to empty array
              setAssignAreaId((prevAssignAreaId) =>
                prevAssignAreaId.filter(
                  (assignArea) => assignArea.inbound_id !== inboundId
                )
              )
              // load data
              loadData(id)
              Common.showPopUpSuccessMessage('Success')
            }
          } catch (err) {
            Swal.close()
            const errMsg =
              err?.response?.data?.errors?.message || 'An error occurred'
            const swalWithBootstrapButtonError = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-primary-orange',
                cancelButton: 'btn btn-transparent',
                actions:
                  'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
              },
              buttonsStyling: false
            })
            swalWithBootstrapButtonError
              .fire({
                html: `<p>Send to ERP <span class='text-danger fw-bold text-uppercase'>failed</span></p><br><p>Reason: ${errMsg}</p>`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Resend',
                allowOutsideClick: false,
                showCloseButton: true
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  try {
                    if (!checkAssignArea(inboundId)) {
                      Swal.close()
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Destination area is required'
                      })
                      return
                    }
                    const res = await api.post(
                      `inbound/pr-draft/assign-area/${inboundId}`,
                      {
                        areas: assignAreaId.find(
                          (inbound) => inbound.inbound_id === inboundId
                        )?.areas
                      }
                    )
                    if (res.data.success) {
                      Swal.close()
                      // override state value assign area id to empty array
                      setAssignAreaId((prevAssignAreaId) =>
                        prevAssignAreaId.filter(
                          (assignArea) => assignArea.inbound_id !== inboundId
                        )
                      )
                      // load data
                      loadData(id)
                      Common.showPopUpSuccessMessage('Success')
                    }
                  } catch (err) {
                    Swal.close()
                    const errMsg =
                      err?.response?.data?.errors?.message ||
                      'An error occurred'
                    Common.showPopUpErrorMessage(errMsg)
                  }
                }
              })
          }
        } else {
          return false
        }
      })
  }

  const checkAssignArea = (inboundId) => {
    const lengthItemAssignAreaByInbound = assignAreaId.find((inbound) => {
      return inbound.inbound_id === inboundId
    })?.areas.length
    const lengthItemInbounds = detail?.inbounds.find((inbound) => {
      return inbound.id === inboundId
    })?.items.length

    return lengthItemInbounds === lengthItemAssignAreaByInbound
  }

  const handleDisableInputNoteItem = (data, inboundItemId) => {
    const areaInboundItemId = assignAreaId
      .find((assignArea) => assignArea.inbound_id === data?.id)
      ?.areas.find(
        (area) => area.item_staging_area_id === inboundItemId
      )?.area_id
    const getCodeArea = !areaInboundItemId
      ? null
      : areaData.find((area) => area.value === areaInboundItemId)?.code || ''
    if (
      data?.status === 'waiting_om' &&
      (!data?.erp_code || (data?.has_karantina && !data?.erp_code_karantina)) &&
      getCodeArea === 'KR'
    ) {
      return false
    }
    return true
  }

  const handleInputNoteInbound = (inboundId, inboundIteemId, value) => {
    setDetail((detailPrev) => ({
      ...detailPrev,
      inbounds: detailPrev.inbounds.map((inbound) => {
        if (inbound.id === inboundId) {
          return {
            ...inbound,
            items: inbound.items.map((item) =>
              item.id === inboundIteemId
                ? {
                    ...item,
                    note: value
                  }
                : item
            )
          }
        }
        return inbound
      })
    }))
    setAssignAreaId((assignAreaPrev) =>
      assignAreaPrev.map((inbound) => {
        if (inbound.inbound_id === inboundId) {
          return {
            ...inbound,
            areas: inbound.areas.map((area) =>
              area.item_staging_area_id === inboundIteemId
                ? {
                    ...area,
                    note: value
                  }
                : area
            )
          }
        }
        return inbound
      })
    )
  }

  const handleUpdateItemCoa = (item) => {
    setModalUpdateItemCoa(true)
    setSelectedItemCoa({
      id: item.id,
      coa_pd: item.coa_pd,
      coa_ed: item.coa_ed,
      label_ed: item.label_ed,
      label_pd: item.label_pd,
      batch: item?.batch.batch,
      item: item?.item?.item_name,
      coa_doc: item?.coa_doc,
      coa_status: item?.coa_status
    })
  }

  const statusTextMapping = {
    unassigned: 'unassigned',
    waiting_rnr: 'waiting rnr',
    receiving_progress: 'receiving progress',
    rnr: 'receiving progress',
    waiting_om: 'waiting om',
    request_pnp: 'waiting om',
    waiting_pnp: 'waiting pnp',
    pnp: 'put away',
    put_away: 'put away',
    done: 'done',
    revised: 'waiting rnr'
  }

  const statusCoaMapping = {
    unchecked: 'unchecked',
    match: 'match',
    not_match: 'not match'
  }

  const handleDownloadFileAttachment = (attachment) => {
    window.open(attachment, '_blank')
  }

  const handleDownloadLabel = async (labelId) => {
    try {
      const payload = {
        inbound_staging_id: detail?.po_info?.is_external ? null : labelId,
        item_coa_id: detail?.po_info?.is_external ? labelId : null
      }
      const res = await api.post(`/inbound/pr-draft/${id}/label`, payload, {
        responseType: 'arraybuffer'
      })
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `download-label.pdf`) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }

  return (
    <>
      <Container className="mb-5">
        {isLoading && (
          <>
            <div>
              <Row>
                <Col md={12}>
                  <div
                    style={{ height: '100vh' }}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex flex-row justify-content-center gap-2">
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                        </div>
                        <p
                          className="fw-bold text-center"
                          style={{ fontSize: '13px' }}
                        >
                          Loading...
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
        {!isLoading && (
          <>
            <Row>
              <Col md={12} className="mt-3">
                <Breadcrumb
                  label={detail?.po_info?.po_code}
                  link="/inbound/purchase-receipt"
                  back="PURCHASE RECEIPT"
                  type="BadgeStatusPrList"
                  status={detail?.po_info?.draft_status}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Formik
                initialValues={{
                  do: '',
                  pib_date: '',
                  expedition: '',
                  aju: '',
                  pic_number: '',
                  vehicle: '',
                  total_pack: ''
                }}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={
                  detail?.po_info?.draft_status !== 'done'
                    ? PurchaseReceiptCreateImportSchema
                    : null
                }
                onSubmit={() => {
                  handleSubmit()
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  resetForm
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Row className="mb-3">
                        <Label
                          label="#po"
                          value={detail?.po_info?.po_code}
                          col={3}
                        />
                        <Label
                          label="po type"
                          value={detail?.po_info?.pr_type}
                          col={3}
                        />
                        <Label
                          label="branch"
                          value={detail?.po_info?.branch}
                          col={3}
                        />
                        <Label
                          label="supplier"
                          value={detail?.po_info?.supplier}
                          col={3}
                        />
                        <Label
                          label="SERIES"
                          value={detail?.po_info?.series_number}
                          col={3}
                        />
                        <Label
                          label="accepted warehouse"
                          value={detail?.po_info?.acc_warehouse_id}
                          col={3}
                        />
                        <Label
                          label="method"
                          value={transformKey(detail?.po_info?.pr_method || '')}
                          col={3}
                        />
                      </Row>
                      {/* ARRIVAL */}
                      <Row className="shadow-sm mb-5 bg-white rounded">
                        <Col md={12} className="mb-2">
                          <h5 className="text-secondary">
                            ARRIVAL INFO
                            <FontAwesomeIcon
                              style={{ cursor: 'pointer' }}
                              icon={icon}
                              className="ms-3"
                              onClick={() => {
                                setOpen(!open)
                                open === true
                                  ? setIcon(faCaretDown)
                                  : setIcon(faCaretUp)
                              }}
                              aria-expanded={open}
                              aria-controls="example-collapse-text"
                            />
                          </h5>
                        </Col>
                        <Collapse in={open}>
                          <div id="example-collapse-text">
                            <Row className="mb-3">
                              {detail?.po_info?.pr_type === 'local' && (
                                <>
                                  <Label
                                    label="#do"
                                    value={detail?.arrival_info?.do}
                                    col={3}
                                  />
                                  <Label
                                    label="expedition"
                                    value={detail?.arrival_info?.expedition}
                                    col={3}
                                  />
                                  <Label
                                    label="pic name"
                                    value={detail?.arrival_info?.pic_name}
                                    col={3}
                                  />
                                  <Label
                                    label="pic number"
                                    value={detail?.arrival_info?.pic_number}
                                    col={3}
                                  />
                                </>
                              )}
                              {detail?.po_info?.pr_type === 'import' && (
                                <>
                                  <Label
                                    label="#do"
                                    value={detail?.arrival_info?.do}
                                    col={3}
                                  />
                                  <Label
                                    label="expedition"
                                    value={detail?.arrival_info?.expedition}
                                    col={3}
                                  />
                                  <Label
                                    label="pib date"
                                    value={convertDateWithoutTime(
                                      detail?.arrival_info?.pib_date
                                    )}
                                    col={3}
                                  />
                                  <Label
                                    label="#aju"
                                    value={detail?.arrival_info?.aju}
                                    col={3}
                                  />
                                  <Label
                                    label="pic name"
                                    value={detail?.arrival_info?.pic_name}
                                    col={3}
                                  />
                                  <Label
                                    label="pic number"
                                    value={detail?.arrival_info?.pic_number}
                                    col={3}
                                  />
                                </>
                              )}
                            </Row>
                          </div>
                        </Collapse>
                      </Row>
                      {/* VEHICLE */}
                      {detail?.po_info?.pr_method !== 'cross_dock' && (
                        <Row className="shadow-sm mb-5 bg-white rounded">
                          <Col md={12} className="mb-3">
                            <h5 className="text-secondary">
                              VEHICLE
                              <FontAwesomeIcon
                                style={{ cursor: 'pointer' }}
                                icon={iconVehicle}
                                className="ms-3"
                                onClick={() => {
                                  setOpenVehicle(!openVehicle)
                                  openVehicle === true
                                    ? setIconVehicle(faCaretDown)
                                    : setIconVehicle(faCaretUp)
                                }}
                                aria-expanded={openVehicle}
                                aria-controls="example-collapse-text1"
                              />
                            </h5>
                          </Col>
                          <Collapse in={openVehicle}>
                            <div id="example-collapse-text1">
                              <Row>
                                <TextField
                                  label="plate no."
                                  name="vehicle"
                                  value={values.vehicle}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                  placeholder="Input vehicle"
                                  col={10}
                                  bold
                                  required
                                  disabled={
                                    detail?.po_info?.draft_status === 'done'
                                      ? true
                                      : false
                                  }
                                />
                                <Col md={2} className="text-end ">
                                  {detail?.po_info?.draft_status === 'done' ? (
                                    <Button
                                      type="button"
                                      className="btn btn-primary-blue w-100"
                                      style={{ marginTop: '27px' }}
                                      onClick={() =>
                                        addVehicle(values.vehicle, resetForm)
                                      }
                                      disabled={true}
                                    >
                                      Add Vehicle
                                    </Button>
                                  ) : (
                                    <Button
                                      type="button"
                                      className="btn btn-primary-blue w-100"
                                      style={{ marginTop: '27px' }}
                                      onClick={() =>
                                        addVehicle(values.vehicle, resetForm)
                                      }
                                      disabled={!values.vehicle}
                                    >
                                      Add Vehicle
                                    </Button>
                                  )}
                                </Col>
                                <Col md={12} className="mt-3">
                                  {newDataVehicles.length > 0 && (
                                    <Table
                                      hover
                                      responsive="sm"
                                      className="shadow-sm mb-4 bg-white rounded"
                                      size="sm"
                                      style={{ fontSize: '12px' }}
                                    >
                                      <thead className="table-secondary">
                                        <tr>
                                          <th className="p-3 align-middle">
                                            No
                                          </th>
                                          <th
                                            className="p-3"
                                            style={{ width: '30%' }}
                                          >
                                            Plate No
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            SIM
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Validity
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Notes
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            STNK
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Validity
                                          </th>
                                          <th
                                            className="p-3 text-end align-middle"
                                            style={{ width: '20%' }}
                                          >
                                            Notes
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {newDataVehicles.map(
                                          (vehicle, index) => (
                                            <tr key={index}>
                                              <td className="p-3 align-middle">
                                                {index + 1}
                                              </td>
                                              <td className="p-3 align-middle">
                                                {vehicle.plate_no || '-'}
                                              </td>
                                              <td className="p-3 text-end align-middle text-primary">
                                                <a
                                                  href={
                                                    vehicle.sim_doc
                                                      ? vehicle.sim_doc
                                                      : '#'
                                                  }
                                                  target="_blank"
                                                  className="text-decoration-none"
                                                  rel="noreferrer"
                                                >
                                                  {vehicle.sim_doc
                                                    ? vehicle.sim_doc.name ||
                                                      vehicle.sim_doc
                                                        .split('/')
                                                        .pop()
                                                    : '-'}
                                                </a>
                                              </td>
                                              <td className="p-3 text-end align-middle">
                                                {vehicle.sim_validity &&
                                                vehicle.sim_validity !== ''
                                                  ? 'Valid'
                                                  : 'Invalid'}
                                              </td>
                                              <td className="p-3 text-end align-middle">
                                                {vehicle.sim_notes || '-'}
                                              </td>
                                              <td className="p-3 text-end align-middle text-primary">
                                                <a
                                                  href={
                                                    vehicle.stnk_doc
                                                      ? vehicle.stnk_doc
                                                      : '#'
                                                  }
                                                  target="_blank"
                                                  className="text-decoration-none"
                                                  rel="noreferrer"
                                                >
                                                  {vehicle.stnk_doc
                                                    ? vehicle.stnk_doc.name ||
                                                      vehicle.stnk_doc
                                                        .split('/')
                                                        .pop()
                                                    : '-'}
                                                </a>
                                              </td>
                                              <td className="p-3 text-end align-middle">
                                                {vehicle.stnk_validity &&
                                                vehicle.stnk_validity !== ''
                                                  ? 'Valid'
                                                  : 'Invalid'}
                                              </td>
                                              <td className="p-3 text-end align-middle">
                                                {vehicle.stnk_notes || '-'}
                                              </td>
                                              <td className="p-3 text-end align-middle d-flex">
                                                <div className="d-flex flex-row gap-2">
                                                  <button
                                                    className="btn btn-secondary ml-2"
                                                    type="button"
                                                    onClick={() =>
                                                      handleUpdateVehicle(
                                                        vehicle
                                                      )
                                                    }
                                                    disabled={
                                                      detail?.po_info
                                                        ?.draft_status ===
                                                      'done'
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faPenToSquare}
                                                    />
                                                  </button>
                                                  <button
                                                    className="btn btn-secondary ml-2"
                                                    type="button"
                                                    onClick={() =>
                                                      onDeleteItemVehicle(
                                                        vehicle.id
                                                      )
                                                    }
                                                    hidden={
                                                      !(
                                                        vehicle.status ===
                                                          'waiting_rnr' ||
                                                        vehicle.status ===
                                                          'unassigned'
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faTrash}
                                                    />
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Collapse>
                        </Row>
                      )}
                      {/* ITEM & COA */}
                      <Row className="shadow-sm mb-5 bg-white rounded">
                        <Col md={12} className="mb-3">
                          <h5 className="text-secondary">
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="text-danger me-2"
                              hidden={differentDate === false ? false : true}
                            />
                            ITEM & COA
                            <FontAwesomeIcon
                              style={{ cursor: 'pointer' }}
                              icon={iconItemCoa}
                              className="ms-3"
                              onClick={() => {
                                setOpenItemCoa(!openItemCoa)
                                openItemCoa === true
                                  ? setIconItemCoa(faCaretDown)
                                  : setIconItemCoa(faCaretUp)
                              }}
                              aria-expanded={openItemCoa}
                              aria-controls="example-collapse-text2"
                            />
                          </h5>
                        </Col>
                        <Collapse in={openItemCoa}>
                          <div id="example-collapse-text2">
                            <Row className="">
                              <Col md={12} className="mt-3">
                                <Table
                                  hover
                                  responsive="sm"
                                  className="shadow-sm mb-4 bg-white rounded"
                                  size="sm"
                                  style={{ fontSize: '12px' }}
                                >
                                  <thead className="table-secondary">
                                    <tr>
                                      <th className="p-3 align-middle">No</th>
                                      <th
                                        className="p-3 align-middle"
                                        style={{ width: '20%' }}
                                      >
                                        Item
                                      </th>
                                      {!detail?.po_info?.is_external &&
                                        detail?.po_info?.pr_method ===
                                          'to_warehouse' && (
                                          <th className="p-3 align-middle">
                                            Status COA
                                          </th>
                                        )}

                                      <th className="p-3 text-end align-middle">
                                        Batch
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        Total Pack
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        Total Qty
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        COA Document
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        COA MD
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        COA ED
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        Label MD
                                      </th>
                                      <th className="p-3 text-end align-middle">
                                        Label ED
                                      </th>
                                      {detail?.po_info?.is_external && (
                                        <>
                                          <th className="p-3 text-end align-middle">
                                            Destination Area
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Problem
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Remark
                                          </th>
                                        </>
                                      )}
                                      {detail?.po_info?.pr_method ===
                                        'to_warehouse' && (
                                        <th className="p-3 text-center align-middle">
                                          {detail?.po_info?.is_external
                                            ? 'Label'
                                            : 'Action'}
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {detail?.items_coa?.map((item, i) => {
                                      return (
                                        <>
                                          <tr key={i}>
                                            <td className="p-3 align-middle">
                                              {i + 1}
                                            </td>
                                            <td className="p-3 align-middle">
                                              {' '}
                                              {item?.item?.item_name}
                                            </td>
                                            {!detail?.po_info?.is_external &&
                                              detail?.po_info?.pr_method ===
                                                'to_warehouse' && (
                                                <td className="p-3 align-middle">
                                                  <BadgeStatusCOA
                                                    text={
                                                      statusCoaMapping[
                                                        item?.coa_status
                                                      ] || 'unchecked'
                                                    }
                                                  />
                                                </td>
                                              )}
                                            <td className="p-3 text-end align-middle">
                                              <div className="d-flex flex-row gap-2 justify-content-end">
                                                {item?.is_new_batch && (
                                                  <div
                                                    style={{
                                                      marginTop: '-.5rem'
                                                    }}
                                                  >
                                                    <Badge pill bg="success">
                                                      New
                                                    </Badge>
                                                  </div>
                                                )}
                                                {item?.batch
                                                  ? item?.batch?.batch
                                                  : '-'}
                                              </div>
                                            </td>
                                            <td className="p-3 text-end align-middle">
                                              {item?.total_pack !== null
                                                ? item?.total_pack
                                                : '-'}
                                            </td>
                                            <td className="p-3 text-end align-middle">
                                              {item?.total_qty !== null
                                                ? item?.total_qty
                                                : '-'}
                                            </td>
                                            <td className="p-3 text-end align-middle text-primary">
                                              <a
                                                href={
                                                  item.coa_doc
                                                    ? item.coa_doc
                                                    : '#'
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                {item?.coa_doc
                                                  ? item?.coa_doc
                                                      .split('/')
                                                      .splice(-1)
                                                  : '-'}
                                              </a>
                                            </td>
                                            <td className="p-3 text-end align-middle">
                                              {item?.coa_pd ?? '-'}
                                            </td>
                                            <td className="p-3 text-end align-middle">
                                              {item?.coa_ed ?? '-'}
                                            </td>
                                            <td className="p-3 text-end align-middle">
                                              {item?.label_pd ?? '-'}
                                            </td>
                                            <td className="p-3 text-end align-middle">
                                              {item?.label_ed ?? '-'}
                                            </td>
                                            {detail?.po_info?.is_external && (
                                              <>
                                                <td className="p-3 text-end align-middle">
                                                  {item?.area?.name ?? '-'}
                                                </td>
                                                <td className="p-3 text-end align-middle">
                                                  {item?.problem
                                                    ? transformKey(
                                                        item?.problem
                                                      )
                                                    : '-'}
                                                </td>
                                                <td className="p-3 text-end align-middle">
                                                  {item?.remark ?? '-'}
                                                </td>
                                              </>
                                            )}
                                            {!detail?.po_info?.is_external &&
                                              detail?.po_info?.pr_method ===
                                                'to_warehouse' && (
                                                <td className="p-3 align-middle text-center">
                                                  <button
                                                    className="btn btn-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      handleUpdateItemCoa(item)
                                                    }}
                                                    disabled={
                                                      detail?.po_info
                                                        ?.draft_status ===
                                                      'done'
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faPenToSquare}
                                                    />
                                                  </button>
                                                </td>
                                              )}
                                            {detail?.po_info?.is_external &&
                                              detail?.po_info?.pr_method ===
                                                'to_warehouse' && (
                                                <td className="p-3 align-middle text-center">
                                                  <Button
                                                    variant="warning"
                                                    style={{
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                      handleDownloadLabel(
                                                        item?.id
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faFileDownload}
                                                      className="text-white"
                                                    />
                                                  </Button>
                                                </td>
                                              )}
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </Table>
                              </Col>
                              <Col
                                md={12}
                                hidden={
                                  detail?.items_coa?.filter(
                                    (coa) => coa.coa_status === 'not_match'
                                  ).length > 0
                                    ? false
                                    : true
                                }
                                style={{
                                  marginTop: '-10px',
                                  marginBottom: '10px'
                                }}
                              >
                                <small className="text-danger fw-bold">
                                  Different date / Not complete !
                                </small>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </Row>
                      {/* ATTACHMENT */}
                      {detail?.po_info?.is_external &&
                        detail?.attachments?.length > 0 && (
                          <div className="d-flex flex-column gap-2 mb-5">
                            <Row>
                              <Col md={12} className="mb-3">
                                <h5 className="text-secondary text-uppercase">
                                  attachment{' '}
                                  <sup style={{ color: 'red' }}>*</sup>
                                </h5>
                              </Col>
                            </Row>

                            <Row className="mt-1">
                              <Col md={5}>
                                <div className="d-flex flex-column gap-3">
                                  {detail?.attachments?.map(
                                    (attachment, index) => (
                                      <div
                                        className="d-flex justify-content-between px-2 py-3 rounded shadow-sm"
                                        style={{
                                          border: '0.5px solid #E3E3E3'
                                        }}
                                        key={attachment.id}
                                      >
                                        <span className="fs-6 mt-2 ms-2">
                                          {attachment.attachment
                                            ?.split('/')
                                            .pop() || ''}
                                        </span>

                                        <button
                                          type="button"
                                          className="btn btn-secondary me-3"
                                          onClick={() =>
                                            handleDownloadFileAttachment(
                                              attachment.attachment
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faFileDownload}
                                          />
                                        </button>
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      {/* INBOUND */}
                      {detail?.inbounds?.map((data, i) => {
                        return (
                          <>
                            <Row
                              key={i}
                              className="mb-5 shadow-sm bg-white rounded"
                            >
                              <Col xs={12} md={6} lg={6}>
                                <Row>
                                  <Col md={12}>
                                    <h5 className="fw-bold">
                                      <FontAwesomeIcon
                                        icon={faClockRotateLeft}
                                        style={{
                                          fontSize: '14px',
                                          marginRight: '5px',
                                          cursor: 'pointer'
                                        }}
                                        onClick={() => handleTimline(data)}
                                      />
                                      {data.ticket_id}
                                      {!data?.erp_code && (
                                        <sup
                                          style={{
                                            fontSize: '12px',
                                            marginLeft: '5px',
                                            marginTop: '-5px'
                                          }}
                                        >
                                          <BadgeStatusPrLists
                                            text={
                                              statusTextMapping[data.status] ||
                                              'done'
                                            }
                                          />
                                        </sup>
                                      )}
                                      {data?.erp_code && (
                                        <>
                                          <span className="text-warning fw-bold ms-2">
                                            - {data?.erp?.erp_no}
                                          </span>
                                        </>
                                      )}
                                    </h5>
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={6}
                                    lg={6}
                                    className="mt-1 mt-md-4 mb-md-4 mt-lg-4 mb-lg-4"
                                  >
                                    STAGING AREA{' - '}
                                    {data.stagings
                                      .map((data) => data.name)
                                      .join(', ')}
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                xs={12}
                                md={6}
                                lg={6}
                                className="fw-bold text-start text-md-end text-lg-end mt-3 mt-md-1 mt-lg-1 mb-2 mb-md-0 mb-lg-0"
                                style={{ fontSize: '12px' }}
                              >
                                {data.vehicle.plate_no} -{' '}
                                {data.status === 'done'
                                  ? 'DONE'
                                  : statusTextMapping[
                                      data.status
                                    ]?.toUpperCase()}
                              </Col>
                              {data?.items.length === 0 ? (
                                ''
                              ) : (
                                <>
                                  <Col md={12}>
                                    <Table
                                      hover
                                      responsive="sm"
                                      className="shadow-sm mb-4 bg-white rounded"
                                      size="sm"
                                      style={{ fontSize: '12px' }}
                                    >
                                      <thead className="table-secondary">
                                        <tr>
                                          <th className="p-3 align-middle">
                                            Palet
                                          </th>

                                          <th
                                            className="p-3 align-middle"
                                            style={{ width: '20%' }}
                                          >
                                            Item
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Batch
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Pack
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Condition
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Location
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Destination Area
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Note
                                          </th>
                                          <th className="p-3 text-end align-middle">
                                            Label
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.items?.map((item, i) => {
                                          setInboundId(data.id)
                                          return (
                                            <tr key={i}>
                                              <td
                                                className="p-3 align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                {item.palet_id
                                                  ? '#' + item.palet_id
                                                  : '-'}
                                              </td>
                                              <td
                                                className="p-3 align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                {item.item_name}
                                              </td>
                                              <td
                                                className="p-3 text-end align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                {item.batch}
                                              </td>
                                              <td
                                                className="p-3 text-end align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                {item?.total_pack}
                                              </td>
                                              <td
                                                className="p-3 text-end align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                <Badge
                                                  bg={
                                                    item?.condition ===
                                                    'damaged'
                                                      ? 'danger'
                                                      : 'success'
                                                  }
                                                  className="text-uppercase fw-bold p-2"
                                                >
                                                  {item?.condition === 'damaged'
                                                    ? 'rusak'
                                                    : 'Baik'}
                                                </Badge>
                                              </td>
                                              <td
                                                className="p-3 text-end align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                {item.current_location}
                                              </td>
                                              <td
                                                className="p-3 align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                {(!data?.erp_code ||
                                                  (data?.has_karantina &&
                                                    !data?.erp_code_karantina)) &&
                                                  data?.status ===
                                                    'waiting_om' && (
                                                    <Select
                                                      menuPortalTarget={
                                                        document.body
                                                      }
                                                      isSearchable={true}
                                                      isLoading={loadingArea}
                                                      loadingMessage={() =>
                                                        'Loading...'
                                                      }
                                                      options={areaData}
                                                      defaultOptions
                                                      placeholder="Select Assign Area"
                                                      styles={customStyles}
                                                      key={item?.id}
                                                      autoLoad={false}
                                                      onFocus={() =>
                                                        setConditionItemInbound(
                                                          (
                                                            prevConditionItemInbound
                                                          ) => ({
                                                            ...prevConditionItemInbound,
                                                            condition:
                                                              item?.condition
                                                          })
                                                        )
                                                      }
                                                      onInputChange={(e) => {
                                                        setConditionItemInbound(
                                                          {
                                                            inputValue: e,
                                                            condition:
                                                              item?.condition
                                                          }
                                                        )
                                                      }}
                                                      onChange={(e) =>
                                                        handleSelectAreaItemInbound(
                                                          data?.id,
                                                          item?.id,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  )}
                                                {(data?.erp_code ||
                                                  (data?.has_karantina &&
                                                    data?.erp_code_karantina)) && (
                                                  <p className="p-2 text-end d-flex h-100 justify-content-end mt-3">
                                                    {item?.area && (
                                                      <span>{item?.area}</span>
                                                    )}
                                                    {!item?.area && (
                                                      <span
                                                        className="text-danger text-uppercase"
                                                        style={{
                                                          fontWeight: 'bold'
                                                        }}
                                                      >
                                                        tolak
                                                      </span>
                                                    )}
                                                  </p>
                                                )}
                                              </td>
                                              <td
                                                className="p-3 align-middle"
                                                style={{
                                                  backgroundColor: `${
                                                    item.from_mobile
                                                      ? '#FFCCCC'
                                                      : 'white'
                                                  }`
                                                }}
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Ketik Keterangan"
                                                  value={item?.note || ''}
                                                  onChange={(e) =>
                                                    handleInputNoteInbound(
                                                      data?.id,
                                                      item?.id,
                                                      e.target.value || ''
                                                    )
                                                  }
                                                  disabled={handleDisableInputNoteItem(
                                                    data,
                                                    item?.id
                                                  )}
                                                />
                                              </td>
                                              <td className="p-3 align-middle">
                                                <Button
                                                  variant="warning"
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                    handleDownloadLabel(
                                                      item?.id
                                                    )
                                                  }
                                                  disabled={
                                                    ![
                                                      'put_away',
                                                      'done'
                                                    ].includes(data?.status)
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faFileDownload}
                                                    className="text-white"
                                                  />
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </Table>
                                  </Col>
                                  {data?.status === 'waiting_om' &&
                                    (!data?.erp_code ||
                                      (data?.has_karantina &&
                                        !data?.erp_code_karantina)) && (
                                      <Row>
                                        {data?.erp?.erp_status === false &&
                                          data?.erp?.err_notes && (
                                            <Col key={i} md={7}>
                                              <Row>
                                                <Col md={12}>
                                                  <p>
                                                    {' '}
                                                    {
                                                      data?.erp?.description
                                                    }, <br />
                                                    status:{' '}
                                                    <span
                                                      style={{
                                                        color:
                                                          data?.erp
                                                            ?.erp_status ===
                                                          true
                                                            ? 'green'
                                                            : '#C12727'
                                                      }}
                                                      className="ms-2 fw-bold text-uppercase"
                                                    >
                                                      {data?.erp?.erp_status ===
                                                      true
                                                        ? 'Success'
                                                        : 'Failed'}
                                                    </span>
                                                  </p>
                                                </Col>
                                                <Col md={12}>
                                                  <span>Failed Reason: </span>
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        data?.erp?.err_notes
                                                    }}
                                                  ></div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}
                                        <Col
                                          key={i}
                                          md={
                                            data?.erp?.erp_status === false &&
                                            data?.erp?.err_notes
                                              ? 5
                                              : 12
                                          }
                                          className="d-flex flex-row justify-content-end mb-3"
                                        >
                                          <small className="text-danger me-4 mt-1 fs-6">
                                            Ensure that all destination areas
                                            match the items
                                          </small>

                                          <div>
                                            <Button
                                              className="btn btn-warning text-white"
                                              onClick={() =>
                                                handleSubmitAndSendErp(data?.id)
                                              }
                                              style={{ fontSize: '11.8px' }}
                                            >
                                              Submit & Send to ERP
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    )}
                                </>
                              )}
                            </Row>
                          </>
                        )
                      })}
                      {/* ITEM RECEIPT DETAILS */}
                      <TableItemReceiptDetails detail={detail} />
                      {/* CREATED AT UPDATED AT */}
                      <div className="d-flex flex-column gap-3">
                        <CardInfo>
                          <Row>
                            <Label
                              label="#erp doc. no - Gudang Utama"
                              value={
                                !detail?.po_info?.pr_code_erp
                                  ? '-'
                                  : detail?.po_info?.pr_code_erp
                              }
                              col={4}
                            />
                            <Label
                              label="#erp doc. no - gudang karantina"
                              value={
                                !detail?.po_info?.pr_code_erp_karantina
                                  ? '-'
                                  : detail?.po_info?.pr_code_erp_karantina
                              }
                              col={4}
                            />
                            <Label
                              label="#erp doc. no - gudang reject"
                              value={
                                !detail?.po_info?.pr_code_erp_reject
                                  ? '-'
                                  : detail?.po_info?.pr_code_erp_reject
                              }
                              col={4}
                            />
                          </Row>
                        </CardInfo>
                        <CardInfo>
                          <Row>
                            <Label
                              label="created by"
                              value={
                                !detail?.po_info?.createdBy
                                  ? '-'
                                  : detail?.po_info?.createdBy
                              }
                              col={3}
                            />
                            <Label
                              label="created at"
                              value={
                                !detail?.po_info?.createdAt
                                  ? '-'
                                  : convertDateWithTime(
                                      detail?.po_info?.createdAt
                                    )
                              }
                              col={3}
                            />
                            <Label
                              label="last updated by"
                              value={
                                !detail?.po_info?.updatedBy
                                  ? '-'
                                  : detail?.po_info?.updatedBy
                              }
                              col={3}
                            />
                            <Label
                              label="last updated at"
                              value={
                                !detail?.po_info?.updatedAt
                                  ? '-'
                                  : convertDateWithTime(
                                      detail?.po_info?.updatedAt
                                    )
                              }
                              col={3}
                            />
                          </Row>
                        </CardInfo>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Row>
          </>
        )}
      </Container>

      <ModalUpdateVehiclePrDraft
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        selectedVehicle={selectedVehicle}
        refreshdata={() => loadData(id)}
      />

      <ModalPrDetailTimeline
        show={modalTimeline}
        onHide={() => setModalTimeline(false)}
        detailinbounds={detailInbounds}
        datahistory={dataHistory}
        refreshdata={() => loadData(id)}
      />

      <ModalUpdateItemCoaPrDraftDetail
        show={modalUpdateItemCoa}
        onHide={() => setModalUpdateItemCoa(false)}
        selecteditemcoa={selectedItemCoa}
        refreshdata={() => loadData(id)}
      />
    </>
  )
}

export default PurchaseReceiptDetail
