import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import api from '../../config/AxiosInstance'
import * as Common from '../../utils/Common'
import TablePagination from '../../components/atoms/Table/TablePagination'
import { convertDateWithTime } from '../../helpers/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Loader } from 'react-bootstrap-typeahead'
import { useNavigate } from 'react-router-dom'

function DisposalPage() {
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState([])
  const [selectWarehouse, setSelectWarehouse] = useState(null)
  const [disposals, setDisposals] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const fetchWarehouse = async () => {
    try {
      const params = {
        type: 'external'
      }
      const response = await api.get('/master/warehouses/all', {params})
      const { data } = response.data
      setWarehouses(data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchDisposal = async () => {
    try {
      setLoading(true)
      const params = {
        per_page: meta.per_page,
        page: meta.current_page,
        warehouse_id: selectWarehouse
      }
      const response = await api.get('/disposal', {
        params
      })
      const { data, metadata } = response.data
      setDisposals(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }
  const renderDate = (data) => (
    <div key={data.id}>
      <span>{convertDateWithTime(data.created_at, 'DD-MM-YYYY HH:mm:ss')}</span>
    </div>
  )

  const renderAction = (data) => (
    <div key={data.id}>
      <button
        className="btn btn-secondary"
        onClick={() => navigate(`/disposal/detail/${data.id}`, {
          state: {
            code: data.code
          }
        })}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{ fontSize: '14px' }}
        />
      </button>
    </div>
  )

  const renderDownloadBeritaAcara = (data) => (
    <div key={data.id}>
      <Button
        className="btn btn-primary-blue border-0 text-uppercase"
        // onClick={() => handleCreate()}
      >
        Download
      </Button>
    </div>
  )
  const renderDownloadSjDisposal = (data) => (
    <div key={data.id} className="text-end">
      <Button
        className="btn btn-secondary text-uppercase"
        // onClick={() => handleCreate()}
      >
        <FontAwesomeIcon icon={faFileDownload} style={{ fontSize: '14px' }}/>
      </Button>
    </div>
  )
  const columns = [
    {
      key: 'code',
      label: 'Ticket'
    },
    {
      key: 'warehouse_id',
      label: 'Warehouse'
    },
    {
      key: 'created_at',
      label: 'Created Date',
      component: (data) => renderDate(data)
    },
    {
      key: 'sj_disposal',
      label: 'SJ Disposal',
      component: (data) => renderDownloadSjDisposal(data),
    },
    {
      key: 'download_berita_acara',
      label: 'Berita Acara',
      component: (data) => renderDownloadBeritaAcara(data)
    },
    {
      key: 'action',
      label: 'Action',
      component: (data) => renderAction(data)
    }
  ]

  const handleSelectMaterialIssue = () => {
    fetchDisposal()
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  useEffect(() => {
    fetchWarehouse()
  }, [])
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1 className="header-title-style">Disposal</h1>
          </Col>
        </Row>
        <Row>
          <Col md={11}>
            <Form.Group>
              <Form.Label>
                <small
                  className="text-secondary fw-bold text-uppercase"
                  style={{ fontSize: '11px' }}
                >
                  warehouse name
                </small>
              </Form.Label>
              <Form.Select
                name="warehouse"
                id="warehouse"
                value={selectWarehouse}
                onChange={(e) => setSelectWarehouse(e.target.value)}
              >
                <option value="">All</option>
                {warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.warehouse_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col
            md={1}
            className="d-flex justify-content-end justify-content-md-start justify-content-lg-start align-items-end pt-0 pt-md-4 pt-lg-4"
          >
            <button
              className={`btn ${
                !selectWarehouse ? 'btn-secondary' : 'btn-primary-orange'
              } mt-1`}
              onClick={() => handleSelectMaterialIssue()}
              disabled={!selectWarehouse}
            >
              Select
            </button>
          </Col>
        </Row>
        <Row className="p-2 me-2 my-3">
          <Table>
            <thead className="table-secondary">
              <tr>
                {columns.map((column) => (
                  <th className="align-middle p-3" key={column.key}>
                    <span>{column.label}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={columns.length} className="p-3 text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading && disposals.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="p-3 text-center">
                    No data
                  </td>
                </tr>
              )}
              {!loading &&
                disposals.length > 0 &&
                disposals.map((disposal, indexStock) => (
                  <tr key={indexStock}>
                    {columns.map((column) => (
                      <td key={column.key} className="p-3 align-middle">
                        {column.component && column.component(disposal)}
                        {!column.component && disposal[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="p-3" colSpan={columns.length}>
                  <TablePagination
                    meta={meta}
                    handlePageChange={handlePageChange}
                    handleOnChangeMeta={handleOnChangeMeta}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Row>
      </Container>
    </div>
  )
}
export default DisposalPage;
