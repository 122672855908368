import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as Common from '../../../utils/Common'
import api from '../../../config/AxiosInstance'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { capitalizeFirstLetter, convertDateWithoutTime, transformKey } from '../../../helpers/constants'
import LabelDetail from '../../../components/atoms/LabelDetail'

function MasterStockQuarantineRejectPage() {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState(null)
  const navigate = useNavigate()
  const getData = async () => {
    try {
      let url = `master/stocks/karantina/${id}`
      if (searchParams.get('type') === 'reject') {
        url = `master/stocks/reject/${id}`
      }
      const response = await api.get(url)
      const { success, data } = response.data
      if (success) {
        setData(data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
      navigate(-1)
    }
  }

  const handlePressBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])

  return (
    <div>
      <Container>
        <Row className="mb-3">
          <h4 className="header-title-style">{capitalizeFirstLetter(searchParams.get('type'))} Detail</h4>
        </Row>
        <Row>
          <Col className="d-flex align-items-center gap-4">
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faArrowLeft}
              onClick={handlePressBack}
            ></FontAwesomeIcon>
            <h5 className="pt-1">{data?.item_name || ''}</h5>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={2}>
            <LabelDetail header="quarantine date" value={data?.date ? convertDateWithoutTime(data?.date) : '-'} />
          </Col>
          <Col md={2}>
            <LabelDetail header="batch" value={data?.batch || '-'} />
          </Col>
          <Col md={2}>
            <LabelDetail header="location" value={data?.batch || '-'} />
          </Col>
          <Col md={2}>
            <LabelDetail header="pallet id" value={data?.palet_id || '-'} />
          </Col>
          <Col md={2}>
            <LabelDetail header="problem" value={ data?.item_problem ? transformKey(data?.item_problem): '-'} />
          </Col>
          <Col md={2}>
            <LabelDetail header="related ticket" value={data?.item_reference || '-'} />
          </Col>
          <Col md={2}>
            <LabelDetail header="remark" value={data?.item_remark || '-'} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MasterStockQuarantineRejectPage
