import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

function CardInfo({children}) {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            {children}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default CardInfo
