import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { ModalUpdateVehiclePR } from '../../../utils/validationSchema'
import TextField from '../../Input/TextInput'
import { capitalizeWords } from '../../../helpers/constants'

const title = {
  fontSize: '9px',
  lineHeight: '12px',
  color: '#828282'
}

const ModalUpdateVehiclePrDraft = (props) => {
  const { updateVehicleInList, selectedVehicle, refreshdata } = props
  const [file, setFile] = useState(false)
  const [fileSimImg, setFileSimImg] = useState()
  const [fileStnk, setFileStnk] = useState(false)
  const [fileStnkImg, setFileStnkImg] = useState()
  const maxSize = 1242880 // max size file upload\
  const typeImage = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
  const fileInputSim = useRef(null)
  const fileInputStnk = useRef(null)

  useEffect(() => {
    if (props.show === false) {
      setFile(false)
    }

    selectedVehicle?.sim_doc === '' ||
    selectedVehicle?.sim_doc?.name === '' ||
    selectedVehicle?.sim_doc === null
      ? setFile(false)
      : setFile(selectedVehicle?.sim_doc?.name || selectedVehicle?.sim_doc) &&
        setFileSimImg(
          selectedVehicle?.sim_doc?.name || selectedVehicle?.sim_doc
        )

    selectedVehicle?.stnk_doc === '' ||
    selectedVehicle?.stnk_doc?.name === '' ||
    selectedVehicle?.stnk_doc === null
      ? setFileStnk(false)
      : setFileStnk(
          selectedVehicle?.stnk_doc?.name || selectedVehicle?.stnk_doc
        ) &&
        setFileStnkImg(
          selectedVehicle?.stnk_doc?.name || selectedVehicle?.stnk_doc
        )
  }, [props.show, selectedVehicle])

  const filehandleFileSim = (e) => {
    const size = e.target.files[0].size
    const typeFile = e.target.files[0].type

    if (size > maxSize) {
      Common.showPopUpErrorMessage('Cant upload file more than 5 MB')
    } else if (!typeImage.includes(typeFile)) {
      Common.showPopUpErrorMessage('File upload must jpg/jpeg/png or pdf')
    } else {
      setFile(e.target.files[0].name)
      setFileSimImg(e.target.files[0])
    }
  }

  const filehandleFileStnk = (e) => {
    const size = e.target.files[0].size
    const typeFile = e.target.files[0].type

    if (size > maxSize) {
      Common.showPopUpErrorMessage('Cant upload file more than 5 MB')
    } else if (!typeImage.includes(typeFile)) {
      Common.showPopUpErrorMessage('File upload must jpg/jpeg/png or pdf')
    } else {
      setFileStnk(e.target.files[0].name)
      setFileStnkImg(e.target.files[0])
    }
  }

  const handleSubmit = async (values) => {
    if (updateVehicleInList === undefined) {
      const data = {
        ...values,
        sim_doc: fileSimImg,
        stnk_doc: fileStnkImg
      }
      const res = await api.postForm(
        `inbound/pr-draft/vehicle/${selectedVehicle.id}`,
        data
      )
      if (res.status === 200) {
        props.onHide()
        Common.showPopUpSuccessMessage(res.data.message)
        refreshdata()
      }
    } else {
      updateVehicleInList({
        ...values,
        sim_doc: fileSimImg,
        stnk_doc: fileStnkImg
      })
      props.onHide()
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Vehicle Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            id: selectedVehicle.id || '',
            plate_no: selectedVehicle.plate_no || '',
            sim_validity: selectedVehicle.sim_validity,
            sim_notes: selectedVehicle.sim_notes || '',
            sim_doc: selectedVehicle.sim_doc || '',
            stnk_notes: selectedVehicle.stnk_notes || '',
            stnk_validity: selectedVehicle.stnk_validity,
            stnk_doc: selectedVehicle.stnk_doc || ''
          }}
          enableReinitialize={true}
          validationSchema={ModalUpdateVehiclePR}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values)
            resetForm()
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            isValid,
            dirty,
            touched,
            setFieldValue,
            errors
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Label className="fw-bold">
                      <h4>{selectedVehicle.plate_no}</h4>
                    </Form.Label>
                  </Col>
                  {/* SIM */}
                  <>
                    <Col md={12} className="mt-3">
                      <Form.Label className="fw-normal">
                        <h6>SIM</h6>
                      </Form.Label>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <Form.Label className="fw-bold">
                            <small style={title}>
                              DOC <sup style={{ color: 'red' }}>*</sup>
                            </small>
                          </Form.Label>
                        </Col>
                        <Col md={12}>
                          <Row>
                            {file ? (
                              <Col md={6}>
                                <div className="shadow-sm bg-white rounded">
                                  <div className="d-flex flex-row gap-3 ms-2">
                                    <div className="d-flex flex-column justify-content-center">
                                      <FontAwesomeIcon
                                        icon={faXmark}
                                        className="fa-2x"
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '20px'
                                        }}
                                        onClick={() => {
                                          setFile(false)
                                          setFieldValue('sim_doc', '')
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="d-flex flex-column justify-content-center h-100"
                                      style={{ width: '100%' }}
                                    >
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          wordBreak: 'break-word',
                                          whiteSpace: 'normal'
                                        }}
                                      >
                                        {file}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              <Col md={6}>
                                <label
                                  htmlFor="file"
                                  onClick={() => fileInputSim.current.click()}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlusSquare}
                                    className="fa-2x"
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '25px'
                                    }}
                                  />
                                </label>
                                <input
                                  id="file-upload"
                                  type="file"
                                  onChange={(e) => {
                                    filehandleFileSim(e)
                                    setFieldValue(
                                      'sim_doc',
                                      e.target.files[0].name
                                    )
                                  }}
                                  ref={fileInputSim}
                                  hidden={true}
                                />
                              </Col>
                            )}
                            <Col md={6} style={{ marginTop: '-2rem' }}>
                              <Row>
                                <Col md={12}>
                                  <Form.Label className="fw-bold">
                                    <small style={title}>
                                      VALIDITY{' '}
                                      <sup style={{ color: 'red' }}>*</sup>
                                    </small>
                                  </Form.Label>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col
                                      onChange={() => {
                                        setFieldValue('sim_validity', true)
                                      }}
                                    >
                                      <Field
                                        type="radio"
                                        name="sim_validity"
                                        value={true}
                                        checked={values?.sim_validity === true}
                                        id="sim_validity_valid"
                                      />
                                      <label
                                        className="ms-2"
                                        htmlFor="sim_validity_valid"
                                      >
                                        Valid
                                      </label>
                                    </Col>
                                    <Col
                                      onChange={() => {
                                        setFieldValue('sim_validity', false)
                                      }}
                                    >
                                      <Field
                                        type="radio"
                                        name="sim_validity"
                                        value={false}
                                        checked={values?.sim_validity === false}
                                        id="sim_validity_invalid"
                                      />
                                      <label
                                        className="ms-2"
                                        htmlFor="sim_validity_invalid"
                                      >
                                        Invalid
                                      </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={12} className="mt-3">
                      <TextField
                        labelsm="notes"
                        name="sim_notes"
                        value={values.sim_notes}
                        handleChange={(e) => {
                          const capitalizedValue = capitalizeWords(
                            e.target.value
                          )
                          setFieldValue('sim_notes', capitalizedValue)
                        }}
                        handleBlur={handleBlur}
                        placeholder="Keterangan"
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                  </>
                  {/* STNK */}
                  <>
                    <Col md={12} className="mt-4">
                      <Form.Label className="fw-normal">
                        <h6>STNK</h6>
                      </Form.Label>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <Form.Label className="fw-bold">
                            <small style={title}>
                              DOC <sup style={{ color: 'red' }}>*</sup>
                            </small>
                          </Form.Label>
                        </Col>
                        <Col md={12}>
                          <Row>
                            {fileStnk ? (
                              <Col md={6}>
                                <div className="shadow-sm bg-white rounded">
                                  <div className="d-flex flex-row gap-3 ms-2">
                                    <div className="d-flex flex-column justify-content-center">
                                      <FontAwesomeIcon
                                        icon={faXmark}
                                        className="fa-2x"
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '20px'
                                        }}
                                        onClick={() => {
                                          setFileStnk(false)
                                          setFieldValue('stnk_doc', '')
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="d-flex flex-column justify-content-center h-100"
                                      style={{ width: '100%' }}
                                    >
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          wordBreak: 'break-word',
                                          whiteSpace: 'normal'
                                        }}
                                      >
                                        {fileStnk}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              <Col md={6}>
                                <label
                                  htmlFor="file"
                                  onClick={() => fileInputStnk.current.click()}
                                >
                                  <div className="d-flex flex-column justify-content-center">
                                    <FontAwesomeIcon
                                      icon={faPlusSquare}
                                      className="fa-2x"
                                      style={{
                                        cursor: 'pointer',
                                        fontSize: '25px'
                                      }}
                                    />
                                  </div>
                                </label>
                                <input
                                  id="file-upload"
                                  type="file"
                                  onChange={(e) => {
                                    filehandleFileStnk(e)
                                    setFieldValue(
                                      'stnk_doc',
                                      e.target.files[0].name
                                    )
                                  }}
                                  ref={fileInputStnk}
                                  hidden={true}
                                />
                              </Col>
                            )}
                            <Col md={6} style={{ marginTop: '-2rem' }}>
                              <Row>
                                <Col md={12}>
                                  <Form.Label className="fw-bold">
                                    <small style={title}>
                                      VALIDITY{' '}
                                      <sup style={{ color: 'red' }}>*</sup>
                                    </small>
                                  </Form.Label>
                                </Col>
                                <Col md={12}>
                                  <Row>
                                    <Col
                                      onChange={() => {
                                        setFieldValue('stnk_validity', true)
                                      }}
                                    >
                                      <Field
                                        type="radio"
                                        name="stnk_validity"
                                        value={true}
                                        checked={values.stnk_validity === true}
                                        id="stnk_validity_valid"
                                      />
                                      <label
                                        className="ms-2"
                                        htmlFor="stnk_validity_valid"
                                      >
                                        Valid
                                      </label>
                                    </Col>
                                    <Col
                                      onChange={() => {
                                        setFieldValue('stnk_validity', false)
                                      }}
                                    >
                                      <Field
                                        type="radio"
                                        name="stnk_validity"
                                        value={false}
                                        checked={values.stnk_validity === false}
                                        id="stnk_validity_invalid"
                                      />
                                      <label
                                        className="ms-2"
                                        htmlFor="stnk_validity_invalid"
                                      >
                                        Invalid
                                      </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={12} className="mt-3">
                      <TextField
                        labelsm="notes"
                        name="stnk_notes"
                        value={values.stnk_notes}
                        handleChange={(e) => {
                          const capitalizedValue = capitalizeWords(
                            e.target.value
                          )
                          setFieldValue('stnk_notes', capitalizedValue)
                        }}
                        handleBlur={handleBlur}
                        placeholder="Keterangan"
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                  </>
                </Row>
                <Modal.Footer className="mt-3">
                  <Button
                    type="submit"
                    variant={!isValid || !dirty ? 'secondary' : 'warning'}
                    className="text-white"
                    disabled={!isValid || !dirty}
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateVehiclePrDraft
