import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import * as Common from '../../../utils/Common'
import {
  updateCOA,
  updateCOACrossDockOrTypeExternal
} from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import DatePickerComponent from '../../Input/DatePicker/index'
import { convertDateWithoutTime } from '../../../helpers/constants'

function ModalUpdateItemCoaPrDraft(props) {
  const { selecteditemcoa, updateitemcoasinlist } = props
  const title = {
    fontSize: '9px',
    lineHeight: '12px',
    color: '#828282'
  }
  const [file, setFile] = useState(false)
  const fileInputSim = useRef(null)
  const [fileSimImg, setFileSimImg] = useState({})

  useEffect(() => {
    selecteditemcoa?.coa !== ''
      ? setFile(selecteditemcoa?.coa?.name) &&
        setFileSimImg(selecteditemcoa?.coa?.name)
      : setFile(false)
  }, [props.show, selecteditemcoa])

  const filehandleFileCoa = (e) => {
    const size = e.target.files[0].size / (1024 * 1024)
    const typeFile = e.target.files[0].type

    if (size > 10) {
      Common.showPopUpErrorMessage('Cant upload file more than 10 MB')
    } else if (typeFile !== 'application/pdf') {
      Common.showPopUpErrorMessage('File upload PDF')
    } else {
      setFile(e.target.files[0].name)
      setFileSimImg(e.target.files[0])
    }
  }

  const handleSubmit = (values) => {
    let problem = ''
    if (props.typeWarehouseExternal) {
      problem =
        values.coa_pd === values.label_pd && values.coa_ed === values.label_ed
          ? ''
          : 'coa_not_match'
    }
    updateitemcoasinlist({
      ...values,
      coa: fileSimImg,
      problem
    })
    props.onHide()
  }

  const variantButtonSubmit = (dirty, valid, values) => {
    if (!file || !dirty || !valid) {
      return 'secondary'
    }
    if (
      props.method === 'cross_dock' &&
      !(
        convertDateWithoutTime(values.coa_pd) ===
          convertDateWithoutTime(values.label_pd) &&
        convertDateWithoutTime(values.coa_ed) ===
          convertDateWithoutTime(values.label_ed)
      )
    ) {
      return 'secondary'
    }
    return 'warning'
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update COA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            id: selecteditemcoa.id,
            items: selecteditemcoa.items,
            batch: selecteditemcoa.batch,
            batch_id: selecteditemcoa.batch_id,
            item_id: selecteditemcoa.item_id,
            coa: '',
            coa_pd: selecteditemcoa.coa_pd || '',
            coa_ed: selecteditemcoa.coa_ed || '',
            label_pd: selecteditemcoa.label_pd || '',
            label_ed: selecteditemcoa.label_ed || '',
            total_pack: parseInt(selecteditemcoa.total_pack),
            total_qty: parseInt(selecteditemcoa.total_qty),
            conversion_uom: selecteditemcoa.conversion_uom,
            conversion_qty_uom: selecteditemcoa.conversion_qty_uom,
            conversion_factor: selecteditemcoa.conversion_factor,
            area_id: selecteditemcoa.area_id,
            remark: selecteditemcoa.remark,
            problem: selecteditemcoa.problem
          }}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={
            props.method === 'to_warehouse' && !props.typeWarehouseExternal
              ? updateCOA
              : updateCOACrossDockOrTypeExternal
          }
          onSubmit={(values, { resetForm }) => {
            values.coa_pd = moment(values.coa_pd).format('YYYY-MM-DD')
            values.coa_ed = moment(values.coa_ed).format('YYYY-MM-DD')
            if (props.typeWarehouseExternal) {
              values.label_pd = moment(values.label_pd).format('YYYY-MM-DD')
              values.label_ed = moment(values.label_ed).format('YYYY-MM-DD')
            }
            handleSubmit(values)
            resetForm()
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Label className="fw-bold">
                      <h4>{selecteditemcoa?.items?.name}</h4>
                      <small className="text-muted">
                        {selecteditemcoa?.batch?.batch}
                      </small>
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Form.Label className="fw-bold">
                      <small style={title}>
                        COA<sup style={{ color: 'red' }}>*</sup>
                      </small>
                    </Form.Label>
                  </Col>
                  <Col md={12} style={{ width: '100%' }}>
                    <Row>
                      <Col md={12}>
                        {file ? (
                          <div className="d-flex flex-row gap-2 w-100">
                            <div className="shadow-sm bg-white rounded w-100 p-3">
                              <div className="d-flex flex-row gap-3 ms-2">
                                <div className="d-flex flex-column justify-content-center">
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    className="fa-2x"
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '20px'
                                    }}
                                    onClick={() => {
                                      setFile(false)
                                    }}
                                  />
                                </div>
                                <div
                                  className="d-flex flex-column justify-content-center h-100"
                                  style={{ width: '100%' }}
                                >
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      wordBreak: 'break-word',
                                      whiteSpace: 'normal'
                                    }}
                                  >
                                    {file}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <label
                              htmlFor="file"
                              onClick={() => fileInputSim.current.click()}
                            >
                              <FontAwesomeIcon
                                icon={faPlusSquare}
                                className="fa-2x"
                                style={{
                                  cursor: 'pointer',
                                  fontSize: '25px'
                                }}
                              />
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={(e) => {
                                filehandleFileCoa(e)
                                setFieldValue(
                                  'coa',
                                  e.target?.files && e.target.files[0]
                                )
                              }}
                              ref={fileInputSim}
                              hidden={true}
                              accept="application/pdf"
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <DatePickerComponent
                    label="coa md"
                    name="coa_pd"
                    value={values.coa_pd}
                    handleChange={setFieldValue}
                    handleBlur={() => setFieldTouched('coa_pd', true)}
                    errors={errors}
                    touched={touched}
                    col={6}
                    required
                    disabled={selecteditemcoa?.batch?.newBatch ? true : false}
                  />
                  <DatePickerComponent
                    label="coa ed"
                    name="coa_ed"
                    value={values.coa_ed}
                    handleChange={setFieldValue}
                    handleBlur={() => setFieldTouched('coa_ed', true)}
                    errors={errors}
                    touched={touched}
                    col={6}
                    required
                    disabled={selecteditemcoa?.batch?.newBatch ? true : false}
                  />
                  {(props.typeWarehouseExternal ||
                    props.method === 'cross_dock') && (
                    <>
                      <DatePickerComponent
                        label="label md"
                        name="label_pd"
                        value={values.label_pd}
                        handleChange={setFieldValue}
                        handleBlur={() => setFieldTouched('label_pd', true)}
                        errors={errors}
                        touched={touched}
                        col={6}
                        required
                      />
                      <DatePickerComponent
                        label="label ed"
                        name="label_ed"
                        value={values.label_ed}
                        handleChange={setFieldValue}
                        handleBlur={() => setFieldTouched('label_ed', true)}
                        errors={errors}
                        touched={touched}
                        col={6}
                        required
                      />
                    </>
                  )}
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomButton
                    onClick={handleSubmit}
                    variant={variantButtonSubmit(dirty, isValid, values)}
                    disabled={
                      variantButtonSubmit(dirty, isValid, values) ===
                      'secondary'
                        ? true
                        : false
                    }
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateItemCoaPrDraft
