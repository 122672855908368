import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Common from '../../utils/Common'
import api from '../../config/AxiosInstance'
import { transformKey } from '../../helpers/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
function MaterialIssueDetail() {
  const { id } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    try {
      setLoading(true)
      const response = await api.get(`material-issue/${id}`)
      const { data } = response.data
      setData(data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
      navigate(-1)
    } finally {
      setLoading(false)
    }
  }
  const handleDownloadFileAttachment = (attachment) => {
    window.open(attachment, '_blank')
  }
  const columns = [
    {
      key: 'item_name',
      label: 'Item Name'
    },
    {
      key: 'batch',
      label: 'Batch'
    },
    {
      key: 'total_qty',
      label: 'Total Qty'
    },
    {
      key: 'total_pack',
      label: 'Total Pack'
    },
    {
      key: 'slot',
      label: 'Location'
    },
    {
      key: 'problem',
      label: 'Problem',
      component: (data) => transformKey(data.problem)
    },
    {
      key: 'bu',
      label: 'BU'
    }
  ]
  useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ height: '100vh' }}
      >
        <div className="d-flex flex-column justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column justify-content-start">
                <h6 className="header-title-style" style={{ fontSize: '18px' }}>
                  {state.code}
                </h6>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb fs-6">
                    <li className="breadcrumb-item">
                      <Link
                        to="/material-issue/"
                        className="text-decoration-none"
                      >
                        <span className="text-black fw-bold text-uppercase">
                          Material Issue
                        </span>
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item sj-item active"
                      aria-current="page"
                    >
                      {state.code}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="btn-primary-orange"
                  style={{ height: '35px' }}
                  // onClick={() => handleSelected()}
                >
                  Download Berita Acara
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-2 me-2 my-3">
          <Table>
            <thead className="table-secondary">
              <tr>
                {columns.map((column) => (
                  <th className="align-middle p-3" key={column.key}>
                    <span>{column.label}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.items.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="p-3 text-center">
                    No data
                  </td>
                </tr>
              )}
              {!loading &&
                data?.items.length > 0 &&
                data?.items.map((item, indexItem) => (
                  <tr key={indexItem}>
                    {columns.map((column) => (
                      <td key={column.key} className="p-3 align-middle">
                        {column.component && column.component(item)}
                        {!column.component && item[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
        <Row>
          <div className="d-flex flex-column gap-2 mb-5">
            <Row>
              <Col md={12}>
                <h6 className="text-secondary text-uppercase" style={{ fontSize: '14px' }}>
                  attachment
                </h6>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={5}>
                <div className="d-flex flex-column gap-3">
                  {data?.attachment?.map((attachment, index) => (
                    <div
                      className="d-flex justify-content-between px-2 py-3 rounded shadow-sm"
                      style={{
                        border: '0.5px solid #E3E3E3'
                      }}
                      key={attachment.id}
                    >
                      <span className="fs-6 mt-2 ms-2">
                        {attachment.attachment?.split('/').pop() || ''}
                      </span>

                      <button
                        type="button"
                        className="btn btn-secondary me-3"
                        onClick={() =>
                          handleDownloadFileAttachment(attachment.attachment)
                        }
                      >
                        <FontAwesomeIcon icon={faFileDownload} />
                      </button>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default MaterialIssueDetail
