import React, { useMemo } from 'react'
import {
  faFileDownload,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Nav, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { transformKey } from '../../../helpers/constants'
import Swal from 'sweetalert2'
import { useNotification } from '../../../context/NotificationProvider'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MasterStock = () => {
  const { state } = useLocation()
  const {showNotification} = useNotification();
  const [warehouse, setWarehouse] = useState([])
  const [warehouseid, setWarehouseid] = useState(state?.warehouse_id || null)
  const [stocks, setStocks] = useState([])
  const [selectedID, setSelectedID] = useState([])
  const [selectedProblem, setSelectedProblem] = useState(null)
  const [itemName, setItemName] = useState(state?.item_name || null)
  const [typeWarehouse, setTypeWarehouse] = useState(state?.typeWarehouse || 'internal')
  const [type, setType] = useState(state?.type || 'normal')
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [downloadingRows, setDownloadingRows] = useState({})
  const handleDownloadLabel = async (slotItemId) => {
    try {
      setDownloadingRows((prev) => ({ ...prev, [slotItemId]: true }))
      const params = {
        print_label: 1
      }
      const response = await api.get(`master/stocks/karantina/${slotItemId}`, {
        params,
        responseType: 'arraybuffer'
      })
      const { status, data } = response
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `download-label-${slotItemId}.pdf`) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setDownloadingRows((prev) => ({ ...prev, [slotItemId]: false }))
    }
  }
  const handleRedirectPageQuarantineReject = (slotItemId, type) => {
    navigate(`/master/stock/detail/${slotItemId}?type=${type}`);
  }
  const renderActionTypeNormal = (data) => (
    <div key={data.item_id}>
       <button
          className="btn btn-secondary"
          onClick={() => handleDetail(data.item_id)}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ fontSize: '14px' }}
          />
        </button>
    </div>
  )
  const COLUMN_STOCK_NORMAL = [
    {
      key: 'item_name',
      label: 'Item Name'
    },
    {
      key: 'item_alias',
      label: 'Item Alias'
    },
    {
      key: 'item_code',
      label: 'Item Code'
    },
    {
      key: 'total_pack',
      label: 'Total Pack'
    },
    {
      key: 'total_qty',
      label: 'Total Qty'
    },
    {
      key: 'action',
      label: 'Action',
    }
  ]
  const renderActionStockQuarantineOrReject = (data) => (
    <>
      <div className="d-flex flex-row gap-2 justify-content-start">
        <Button
          variant={downloadingRows[data.slot_item_id] ? 'secondary' : 'warning'}
          style={{
            cursor: 'pointer'
          }}
          onClick={() => handleDownloadLabel(data.slot_item_id)}
          disabled={downloadingRows[data.slot_item_id]}
        >
          <div className="d-flex flex-row gap-2">
            <div className="d-flex flex-column items-content-center justify-content-center">
              <FontAwesomeIcon
                icon={faFileDownload}
                className="text-white"
                style={{ fontSize: '14px' }}
              />
            </div>
          </div>
        </Button>
        <button
          className="btn btn-secondary"
          disabled={downloadingRows[data.slot_item_id]}
          onClick={() => handleRedirectPageQuarantineReject(data.slot_item_id, type === 'rejected' ? 'reject' : type)}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ fontSize: '14px' }}
          />
        </button>
      </div>
    </>
  )
  const COLUMN_STOCK_QUARANTINE_OR_REJECT = [
    {
      key: 'checkbox',
      label: 'Checkbox'
    },
    {
      key: 'item_name',
      label: 'Item Name'
    },
    {
      key: 'batch',
      label: 'Batch'
    },
    {
      key: 'total_pack',
      label: 'Total Pack'
    },
    {
      key: 'total_qty',
      label: 'Total Qty'
    },
    {
      key: 'location',
      label: 'Location'
    },
    {
      key: 'palet_id',
      label: 'Pallet ID'
    },
    {
      key: 'item_problem',
      label: 'Problem',
      component: (data) =>
        data.item_problem ? transformKey(data.item_problem) : '-'
    },
    {
      key: 'item_reference',
      label: 'Related Ticket',
      component: (data) => (
        <span style={{ color: '#2F80ED' }}>{data.item_reference}</span>
      )
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]
  const [columns, setColumns] = useState(COLUMN_STOCK_NORMAL)
  const fetchWarehouse = async () => {
    try {
      const params = {
        type: typeWarehouse
      }
      const response = await api.get('/master/warehouses/all', { params })
      setWarehouse(response.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchStock = async () => {
    try {
      setLoading(true)
      const params = {
        per_page: meta.per_page,
        page: meta.current_page,
        warehouse_id: warehouseid,
        type: typeWarehouse,
        item_name: itemName
      }
      let url = 'master/stocks'
      if (type === 'quarantine') {
        url = 'master/stocks/karantina'
        params.problem = selectedProblem || ''
      }
      if (type === 'rejected') {
        url = 'master/stocks/reject'
        params.problem = selectedProblem || ''
      }
      const response = await api.get(url, {
        params
      })
      setStocks(response.data.data)
      setMeta(response.data.metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleSelected = () => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: 1
    }))
    setView((prevView) => !prevView)
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handleDetail = (code) => {
    const encodedName = encodeURIComponent(code)
    navigate(`/master/stock/${encodedName}`, {
      state: { warehouse_id: warehouseid, item_name: itemName, type }
    })
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  const handleSelectTypeWarehouse = (typeWarehouse) => {
    setTypeWarehouse(typeWarehouse)
    if (warehouseid) {
      setWarehouseid(null)
    }
    if (itemName) {
      setItemName(null)
    }
    if (stocks.length > 0) {
      setStocks([])
    }
    if (selectedProblem) {
      setSelectedProblem(null)
    }
  }
  const handleSelectTypeStock = (typeStock) => {
    setType(typeStock)
    if (typeStock === 'quarantine' || typeStock === 'rejected') {
      setColumns(COLUMN_STOCK_QUARANTINE_OR_REJECT)
    } else {
      setColumns(COLUMN_STOCK_NORMAL)
    }

    if (selectedID.length > 0) {
      setSelectedID([])
    }
  }

  const handleChangeCheckbox = (value = null) => {
    if (!value) {
      if (selectedID.length > 0) {
        setSelectedID([])
      } else {
        const checkedProblemLabel = stocks.every(
          (stock) => stock.item_problem === stocks[0].item_problem
        )
        if (!checkedProblemLabel) {
          return Common.showPopUpErrorMessage(
            'Please select the same problem label'
          )
        }
        setSelectedID(
          stocks.map((stock) => ({
            id: stock.slot_item_id,
            problem: stock.item_problem
          }))
        )
      }
    } else {
      const checkedProblemLabel = selectedID.length > 0 && selectedID.some(
        (valueID) => valueID.problem !== value.problem
      )
      if (checkedProblemLabel) {
        return Common.showPopUpErrorMessage('Please select the same problem label');
      } else {
        setSelectedID((prevSelectedID) => {
          if (prevSelectedID.find((item) => item.id === value.id)) {
            return prevSelectedID.filter((item) => item.id !== value.id)
          } else {
            return [...prevSelectedID, value]
          }
        })
      }
    }
  }
  const handleModalCreateTicket = (typeText) => {
    let title = '';
    let text = '';
    if (typeText === 'send back') {
      text = "Create Transfer to Warehouse (Restock)?";
    }
    if (typeText === 'reject') {
      text = "Create Transfer to Rejected Stock?";
    }
    Swal.fire({
      title,
      text,
      icon: 'warning',
      iconColor: '#828282',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Create',
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-cancel-pr'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let url = "/master/stocks/reject";
          if (typeText === 'send back') {
            url = "/transfer/new-ticket";
          }
          const payload = {
            slot_items: selectedID.map((item) => item.id),
            type: typeWarehouse,
            warehouse_id: warehouseid
          }
          const res = await api.post(url, payload);
          const { status } = res;
          if (status === 200) {
             showNotification("Transfer created!",'success');
             setSelectedID([]); // when success reset to empty array
             fetchStock();
          }
        } catch (err) {
          const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    });
  }
  const handleRedirectPageCreateTicket = (type) => {
    if (!['material_issue', 'disposal'].includes(type)) {
      return Common.showPopUpErrorMessage('Type is not allowed');
    }
    const state = {
      items: selectedID.map((item) => {
        const items = stocks?.find((stock) => stock.slot_item_id === item.id);
        if (items && type === 'material_issue') {
          items.bu = null;
        }
        return items;
      }),
      warehouse_id: warehouseid,
      type,
      typeWarehouse,
    }
    const pathHeader = type.replaceAll('_', '-');
    navigate(`/${pathHeader}/create`, { state }); 
  }
  const columnsMemo = useMemo(() => columns, [columns, downloadingRows])
  useEffect(() => {
    fetchWarehouse()
  }, [typeWarehouse])

  useEffect(() => {
    if (warehouseid) {
      fetchStock()
    }
  }, [meta.per_page, meta.current_page, type, view, selectedProblem])

  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <Col>
          <h1 style={headerTitleStyle}>Master Stock</h1>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={12} className="position-relative">
          <Nav
            variant="underline"
            defaultActiveKey={typeWarehouse}
            style={{ zIndex: '20' }}
          >
            <Nav.Item>
              <Nav.Link
                eventKey={'internal'}
                onClick={() => handleSelectTypeWarehouse('internal')}
                style={{
                  color: `${typeWarehouse === 'internal' ? 'black' : 'grey'}`
                }}
              >
                Internal
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={'external'}
                onClick={() => handleSelectTypeWarehouse('external')}
                style={{
                  color: `${typeWarehouse === 'external' ? 'black' : 'grey'}`
                }}
              >
                External
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div
            className="position-absolute top-100 w-100"
            style={{ zIndex: '-1' }}
          >
            <div
              style={{
                width: '100vw',
                height: '1px',
                backgroundColor: '#C9CACD',
                zIndex: '-1',
                marginLeft: '-1.5rem'
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-row gap-2 gap-md-0 gap-lg-0 align-items-center">
        <Col xs={12} md={4} lg={4}>
          <Form.Group>
            <Form.Label>
              <small
                className="text-secondary fw-bold text-uppercase"
                style={{ fontSize: '11px' }}
              >
                warehouse name
              </small>
            </Form.Label>
            <Form.Select
              name="warehouse"
              id="warehouse"
              value={warehouseid}
              onChange={(e) => setWarehouseid(e.target.value)}
            >
              <option value="">All</option>
              {warehouse.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Form.Group>
            <Form.Label>
              <small
                className="text-secondary fw-bold text-uppercase"
                style={{ fontSize: '11px' }}
              >
                item name
              </small>
            </Form.Label>
            <Form.Control
              value={itemName || ''}
              placeholder="Enter Item Name..."
              onChange={(e) => setItemName(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col
          xs={12}
          md={2}
          lg={2}
          className="d-flex justify-content-end justify-content-md-start justify-content-lg-start align-items-end pt-0 pt-md-4 pt-lg-4"
        >
          <button
            className={`btn ${
              !warehouseid ? 'btn-secondary' : 'btn-primary-orange'
            } mt-1`}
            onClick={() => handleSelected()}
            disabled={!warehouseid}
          >
            View
          </button>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={12} className="position-relative">
          <Nav variant="underline" defaultActiveKey={type}>
            <Nav.Item>
              <Nav.Link
                eventKey={'normal'}
                onClick={() => handleSelectTypeStock('normal')}
                style={{ color: `${type === 'normal' ? 'black' : 'grey'}` }}
              >
                Normal
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={'quarantine'}
                onClick={() => handleSelectTypeStock('quarantine')}
                style={{ color: `${type === 'quarantine' ? 'black' : 'grey'}` }}
              >
                Quarantine
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={'rejected' || 'reject'}
                onClick={() => handleSelectTypeStock('rejected')}
                style={{ color: `${type === 'rejected' ? 'black' : 'grey'}` }}
              >
                Rejected
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {/* <hr /> */}
          <div
            className="position-absolute top-100"
            style={{ zIndex: '-1', width: '80vw' }}
          >
            <div
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#C9CACD',
                zIndex: '-1'
              }}
            />
          </div>
        </Col>
      </Row>
      {(type === 'quarantine' || type === 'rejected') && (
        <Row className="d-flex flex-column items-content-center justify-content-center">
          <Row className="my-4 me-2">
            <div className="d-flex flex-row justify-content-between">
              <div style={{ width: '20%' }}>
                <Form.Select aria-label="select-problem" value={selectedProblem || ''} onChange={(e) => setSelectedProblem(e.target.value)}>
                  <option value="">Select Problem</option>
                  <option value="bocor">Bocor</option>
                  <option value="tidak_ada_label">Tidak Ada Label</option>
                  <option value="tidak_ada_segel">Tidak Ada Segel</option>
                  <option value="coa_not_match">Coa Not Match</option>
                </Form.Select>
              </div>
              <div style={{ width: '80%' }}>
                <div className="d-flex flex-row gap-2 justify-content-end">
                  {type === 'quarantine' && (
                    <>
                      <Button
                        type="button"
                        variant={selectedID.length ? 'warning' : 'secondary'}
                        className={`${selectedID.length ? 'text-light' : ''}`}
                        onClick={() => handleModalCreateTicket('send back')}
                        disabled={!selectedID.length}
                      >
                        Send Back
                      </Button>
                      <Button
                        type="button"
                        variant={selectedID.length ? 'danger' : 'secondary'}
                        onClick={() => handleModalCreateTicket('reject')}
                        disabled={!selectedID.length}
                      >
                        Reject Item
                      </Button>
                    </>
                  )}
                  {type === 'rejected' && (
                    <>
                      <Button
                        type="button"
                        variant={selectedID.length && warehouseid ? 'warning' : 'secondary'}
                        className={`${selectedID.length ? 'text-light' : ''}`}
                        disabled={!selectedID.length || !warehouseid}
                        onClick={() => handleRedirectPageCreateTicket('disposal')}
                      >
                        Disposal
                      </Button>
                      <Button
                        type="button"
                        variant={selectedID.length && typeWarehouse !== 'external' && warehouseid ? 'danger' : 'secondary'}
                        disabled={!selectedID.length || !warehouseid || typeWarehouse === 'external'}
                        onClick={() => handleRedirectPageCreateTicket('material_issue')}
                      >
                        Material Issue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Row>
      )}
      <Row className="p-2 me-2">
        <Table>
          <thead className="table-secondary">
            <tr>
              {columns.map((column) => (
                <th className="align-middle p-3" key={column.key}>
                  {column.key === 'checkbox' && (
                    <input
                      type="checkbox"
                      className="staging-area form-check-input"
                      checked={
                        stocks?.length !== 0 &&
                        stocks?.length === selectedID.length
                      }
                      disabled={!stocks?.length}
                      onChange={() => handleChangeCheckbox()}
                      role="button"
                    />
                  )}
                  {column.key !== 'checkbox' && <span>{column.label}</span>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={columns.length} className="p-3 text-center">
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && stocks.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="p-3 text-center">
                  No data
                </td>
              </tr>
            )}
            {!loading &&
              stocks.length > 0 &&
              stocks.map((stock, indexStock) => (
                <tr key={indexStock}>
                  {columnsMemo.map((column) => (
                    <td key={column.key} className="p-3 align-middle">
                      {column.key === 'checkbox' && (
                        <input
                          type="checkbox"
                          className="staging-area form-check-input"
                          value={stock?.slot_item_id || ''}
                          checked={selectedID.find(
                            (selectId) => selectId.id === stock?.slot_item_id
                          ) ? true : false}
                          onChange={() =>
                            handleChangeCheckbox({
                              id: stock?.slot_item_id,
                              problem: stock?.item_problem
                            })
                          }
                          role="button"
                        />
                      )}
                      {column.key === 'action' &&
                        (type === 'quarantine' || type === 'rejected') &&
                        renderActionStockQuarantineOrReject(stock)}
                      {column.key === 'action' && type === 'normal' && renderActionTypeNormal(stock)}
                      {column.component && column.component(stock)}
                      {column.key !== 'checkbox' &&
                        column.key !== 'action' &&
                        !column.component &&
                        (stock[column.key] ?? '-')}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="p-3" colSpan={columns.length}>
                <TablePagination
                  meta={meta}
                  handlePageChange={handlePageChange}
                  handleOnChangeMeta={handleOnChangeMeta}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </Row>
    </Container>
  )
}

export default MasterStock
