import { Badge } from 'react-bootstrap'

function ActiveInActive({ text = '' }) {
  return (
    <Badge pill bg={text === 'active' ? 'success' : 'secondary'}>
      {text.toUpperCase()}
    </Badge>
  )
}

function ReceiveBill({ text = '' }) {
  let bgColor = 'secondary'

  switch (text) {
    case 'To Receive':
      bgColor = 'primary'
      break
    case 'To Receive and Bill':
    case 'To Bill':
    case 'Draft':
      bgColor = 'secondary'
      break
    case 'Completed':
      bgColor = 'success'
      break
    default:
      bgColor = 'secondary'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function BadgeStatusSj({ text = '', bgColor = '' }) {
  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function BadgeStatusPrList({ text = '' }) {
  let bgColor = 'primary' // Default color

  switch (text) {
    case 'done':
    case 'complete':
      bgColor = 'success'
      break
    case 'waiting':
      bgColor = 'secondary'
      break
    case 'incomplete':
      bgColor = 'danger'
      break
    default:
      bgColor = 'primary'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function BadgeStatusCOA({ text = '' }) {
  let bgColor = 'primary' // Default color

  switch (text) {
    case 'done':
    case 'match':
      bgColor = 'success'
      break
    case 'unchecked':
      bgColor = 'secondary'
      break
    case 'not match':
    case 'not_match':
      bgColor = 'danger'
      break
    default:
      bgColor = 'primary'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function BadgeStatusPrLists({ text = '' }) {
  let bgColor = 'success' // Default color

  switch (text) {
    case 'unassigned':
      bgColor = 'dark'
      break
    case 'waiting rnr':
    case 'waiting pnp':
      bgColor = 'secondary'
      break
    case 'receiving progress':
    case 'put away':
      bgColor = 'primary'
      break
    case 'waiting om':
    case 'revised':
      bgColor = 'warning'
      break
    default:
      bgColor = 'success'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function BadgeStatusSjList({ text = '' }) {
  let bgColor = 'success' // Default color

  switch (text) {
    case 'unassigned':
      bgColor = 'dark'
      break
    case 'waiting rnr':
    case 'waiting pnp':
      bgColor = 'secondary'
      break
    case 'rnr':
    case 'pnp':
      bgColor = 'primary'
      break
    case 'request pnp':
      bgColor = 'warning'
      break
    default:
      bgColor = 'success'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function OutboundHistory({ text = '' }) {
  let bgColor = 'danger'

  switch (text) {
    case 'success':
      bgColor = 'success'
      break

    default:
      bgColor = 'danger'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

function BadgeStatusPrListInbounds({ text = '' }) {
  let bgColor = 'success' // Default color

  switch (text) {
    case 'unassigned':
      bgColor = 'dark'
      break
    case 'waiting':
      bgColor = 'secondary'
      break
    case 'ongoing':
    case 'on going':
      bgColor = 'primary'
      break
    case 'done':
    case 'submit':
      bgColor = 'success'
      break
    default:
      bgColor = 'danger'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}
function BadgeStatusOutboundPlan({ text = '' }) {
  let bgColor = 'success' // Default color

  switch (text) {
    case 'unassigned':
      bgColor = 'dark'
      break
    case 'waiting_pnp':
    case 'waiting_rnr':
    case 'request_loading':
      bgColor = 'secondary'
      break
    case 'pnp_picking':
    case 'rnr_checking':
    case 'loading':
    case 'on_delivery':
      bgColor = 'primary'
      break
    case 'waiting_om':
    case 'finish_loading':
      bgColor = 'warning'
      break
    case 'done':
      bgColor = 'success'
      break
    default:
      bgColor = 'danger'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.replaceAll(/_/g, ' ').toUpperCase()}
    </Badge>
  )
}

function WorkOrderStatus({ text = '' }) {
  let bgColor = ''

  switch (text) {
    case 'waiting':
      bgColor = 'secondary'
      break
    case 'confirmation':
      bgColor = 'warning'
      break
    case 'confirmed':
      bgColor = 'primary'
      break
    case 'done':
      bgColor = 'success'
      break
    case 'problem':
      bgColor = 'danger'
      break
    case 'canceled':
      bgColor = 'danger'
      break
    default:
      bgColor = 'secondary'
      break
  }

  return (
    <Badge pill bg={bgColor}>
      {text.toUpperCase()}
    </Badge>
  )
}

export {
  ActiveInActive,
  BadgeStatusCOA,
  BadgeStatusOutboundPlan,
  BadgeStatusPrList,
  BadgeStatusPrListInbounds,
  BadgeStatusPrLists,
  BadgeStatusSj,
  BadgeStatusSjList,
  OutboundHistory,
  ReceiveBill,
  WorkOrderStatus
}
